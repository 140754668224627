import queryString from 'query-string';
import axios, { Method, AxiosRequestHeaders, AxiosResponse, AxiosError } from 'axios';
import configApi from 'src/serviceConfig.json';
import { TOKEN_EXPIRED_MESSAGE } from 'src/constants/message';
import { getToken, isValidToken, removeToken } from 'src/utils/jwt';

interface RequestFormat {
  method: Method;
  uri: string;
  token?: string;
  query?: object;
  body?: any;
  headers?: AxiosRequestHeaders;
  config?: any;
  isCheckToken?: boolean;
  responseType?: string;
}

axios.interceptors.response.use(
  (res) => res.data,
  (err: AxiosError) => {
    if (err.response?.status === 401) {
      removeToken();
      alert(TOKEN_EXPIRED_MESSAGE);
      window.location.replace(`${window.location.origin}/login`);
    }
    return err.response?.data;
  },
);

export const callApi = (request: RequestFormat): Promise<any> => {
  const token = getToken() as string;
  const { method, uri, body, headers, config, isCheckToken = true, responseType } = request;

  if (isCheckToken) {
    if (
      (!isValidToken(token) && window.location.pathname !== '/login-by-otp') ||
      (!isValidToken(token) && window.location.pathname !== '/auth/reset-password')
    ) {
      removeToken();
      alert(TOKEN_EXPIRED_MESSAGE);
      window.location.replace(`${window.location.origin}/login`);
    }
  }

  axios.defaults.baseURL = configApi['API_GATEWAY'];
  if (token && token.includes('Bearer')) {
    axios.defaults.headers.common['Authorization'] = token;
  } else if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  return axios({
    method: method,
    url: `${uri}${request.query ? `?${queryString.stringify(request.query)}` : ''}`,
    data: body,
    headers: { ...axios.defaults.headers, ...headers, Accept: 'application/json', 'Content-Type': 'application/json' },
    responseType: responseType,
    ...config,
  });
};
