export enum TypeEnum {
  Odd = 'ODD',
  Subscription = 'SUBSCRIPTION',
  ComboData = 'COMBODATA',
}

export enum PayMentMethodEnum {
  SMS = 'SMS',
  IAP = 'IAP',
  MOBIFONE_MONEY = 'MOBIFONE_MONEY',
}

export enum VoucherEnum {
  Star = 'STAR',
  Energy = 'ENERGY',
  Token = 'TOKEN',
}

export enum DisplayOnEnum {
  MOBILE = 'MOBILE',
  WEBSITE = 'WEBSITE',
  ALL = 'ALL',
}

export enum DisplayApplyEnum {
  MOBILE = 'MOBILE',
  WEBSITE = 'WEBSITE',
  NONE = 'NONE',
}

export enum ReportTypeEnum {
  REVENUE = 'REVENUE',
  DATA_IP = 'DATA_IP',
  SUBSCRIPTION_CANCELLATION = 'SUBSCRIPTION_CANCELLATION',
  REVENUE_STATISTIC = 'REVENUE_STATISTIC',
  REVENUE_PACKAGE = 'REVENUE_PACKAGE',
}

export enum PackageStatusEnum {
  RENEW = '0',
  REGISTER = '1',
  PENDING = '2',
  CANCEL = '3',
}
