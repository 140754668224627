export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Buffer custom scalar type */
  Buffer: { input: any; output: any };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any };
};

export type Ads = {
  __typename?: 'Ads';
  _id: Scalars['String']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export enum AdsMobileDisplay {
  GAME = 'GAME',
  HOME = 'HOME',
  MISSION = 'MISSION',
  STORE = 'STORE',
  TOURNAMENT = 'TOURNAMENT',
}

export type AdsPaginate = {
  __typename?: 'AdsPaginate';
  items: Array<Ads>;
  meta: BasePaginationMeta;
};

export type AdsPaginateDto = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export enum AdsPortalDisplay {
  HOME = 'HOME',
}

export type AdsReward = {
  __typename?: 'AdsReward';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  energyPerView: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  viewLimit: Scalars['Int']['output'];
};

export type AdsRewardPaginate = {
  __typename?: 'AdsRewardPaginate';
  items: Array<AdsReward>;
  meta: BasePaginationMeta;
};

export type AdsRewardPaginateDto = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ApproveCommentDto = {
  _id: Scalars['String']['input'];
  status: Scalars['Int']['input'];
};

export type ApproveNewsDto = {
  _id: Scalars['ID']['input'];
  status?: InputMaybe<NewsStatusEnum>;
};

export type ApproveTournamentOfflineNewsDto = {
  _id: Scalars['String']['input'];
  status: Scalars['Int']['input'];
};

export type ApproveVideoDto = {
  _id: Scalars['ID']['input'];
  status?: InputMaybe<VideoStatusEnum>;
};

export type AuthorDto = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type Banner = {
  __typename?: 'Banner';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Float']['output'];
  image: Scalars['String']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['Float']['output'];
  url: Scalars['String']['output'];
};

export type BannerConnection = {
  __typename?: 'BannerConnection';
  items?: Maybe<Array<Banner>>;
  meta: BasePaginationMeta;
};

export type BasePaginationMeta = {
  __typename?: 'BasePaginationMeta';
  currentPage: Scalars['Int']['output'];
  itemCount: Scalars['Int']['output'];
  itemsPerPage: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type BracketInTour = {
  __typename?: 'BracketInTour';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextLooserMatchId?: Maybe<Scalars['String']['output']>;
  nextMatchId?: Maybe<Scalars['String']['output']>;
  participants?: Maybe<Array<TeamInBracketModel>>;
  position?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['DateTime']['output']>;
  state?: Maybe<BracketInTourStateEnum>;
  tourOfflineId: Scalars['String']['output'];
  tournamentRoundText?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type BracketInTourInterface = {
  __typename?: 'BracketInTourInterface';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextLooserMatchId?: Maybe<Scalars['String']['output']>;
  nextMatchId?: Maybe<Scalars['String']['output']>;
  participants?: Maybe<Array<TeamInBracketInterface>>;
  position?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  tourOfflineId?: Maybe<Scalars['String']['output']>;
  tournamentRoundText?: Maybe<Scalars['String']['output']>;
  tournamentRoundVal?: Maybe<Scalars['String']['output']>;
};

export enum BracketInTourStateEnum {
  FINISHED = 'FINISHED',
  HAPPENING = 'HAPPENING',
  UPCOMING = 'UPCOMING',
}

export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  items: Array<CategoryGraphQl>;
  meta: BasePaginationMeta;
};

export type CategoryGraphQl = {
  __typename?: 'CategoryGraphQL';
  _id: Scalars['ID']['output'];
  author?: Maybe<UserGraphQl>;
  createdAt: Scalars['Int']['output'];
  createdBy?: Maybe<Scalars['Int']['output']>;
  desc?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  pathThumbnail?: Maybe<Scalars['String']['output']>;
  totalVideo?: Maybe<Scalars['Float']['output']>;
  type: Scalars['Int']['output'];
  urlThumbnail?: Maybe<Scalars['String']['output']>;
};

export type CategoryPaginateDto = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type CheckTransPayRewards = {
  __typename?: 'CheckTransPayRewards';
  _id: Scalars['String']['output'];
  accId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  data?: Maybe<DataChecked>;
  reqType: Scalars['String']['output'];
  requestId: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['String']['output']>;
  tracenumber?: Maybe<Scalars['String']['output']>;
  transtime?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type ChildCommentGraphQl = {
  __typename?: 'ChildCommentGraphQL';
  _id: Scalars['ID']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  content: Scalars['String']['output'];
  createdAt: Scalars['Int']['output'];
  createdBy?: Maybe<Scalars['Int']['output']>;
  fullname?: Maybe<Scalars['String']['output']>;
  news?: Maybe<News>;
  status?: Maybe<Scalars['Int']['output']>;
  userId: Scalars['Int']['output'];
};

export type CommentGraphQl = {
  __typename?: 'CommentGraphQL';
  _id: Scalars['ID']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  childs?: Maybe<Array<ChildCommentGraphQl>>;
  content: Scalars['String']['output'];
  createdAt: Scalars['Int']['output'];
  createdBy?: Maybe<Scalars['Int']['output']>;
  fullname?: Maybe<Scalars['String']['output']>;
  isReply?: Maybe<Scalars['Boolean']['output']>;
  news?: Maybe<News>;
  status?: Maybe<Scalars['Int']['output']>;
  userId: Scalars['Int']['output'];
};

export type CommentPaginate = {
  __typename?: 'CommentPaginate';
  items: Array<CommentGraphQl>;
  meta: BasePaginationMeta;
};

export type CommentPaginateDto = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type CompetitionFormatDto = {
  formula?: InputMaybe<TourOfflineFormulaEnum>;
  method?: InputMaybe<MethodContinueEnum>;
  methodWinLose?: InputMaybe<TourOfflineMethodWinLoseEnum>;
  rank?: InputMaybe<TourOfflineRankedEnum>;
  turn?: InputMaybe<TourOfflineTurnEnum>;
};

export type CompletionRewardDto = {
  idTask: Scalars['String']['input'];
  isCollect: Scalars['Boolean']['input'];
};

export enum ConsumptionMissionTypeEnum {
  ENERGY = 'ENERGY',
  GPOINT = 'GPOINT',
}

export type Contact = {
  __typename?: 'Contact';
  _id: Scalars['String']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  email: Scalars['String']['output'];
  fullname: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type ContactPaginate = {
  __typename?: 'ContactPaginate';
  items: Array<Contact>;
  meta: BasePaginationMeta;
};

export type ContactPaginateDto = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ContentProvider = {
  __typename?: 'ContentProvider';
  _id: Scalars['String']['output'];
  address?: Maybe<Scalars['String']['output']>;
  adsText?: Maybe<Scalars['String']['output']>;
  apiGateway?: Maybe<Scalars['String']['output']>;
  appleStoreUrl?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  contractCode?: Maybe<Scalars['String']['output']>;
  contractEndDate?: Maybe<Scalars['DateTime']['output']>;
  contractStartDate?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Int']['output']>;
  customerSupport?: Maybe<Scalars['String']['output']>;
  desc?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Scalars['String']['output']>;
  ftp?: Maybe<Ftp>;
  googlePlayUrl?: Maybe<Scalars['String']['output']>;
  guideDesc?: Maybe<Scalars['String']['output']>;
  isActivate: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isValidGame?: Maybe<Scalars['Boolean']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  manager?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  presentor?: Maybe<Scalars['String']['output']>;
  presentorEmail?: Maybe<Scalars['String']['output']>;
  qrImage?: Maybe<Scalars['String']['output']>;
  sharedProfit?: Maybe<Scalars['Float']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ContentProviderG1 = {
  __typename?: 'ContentProviderG1';
  _id: Scalars['String']['output'];
  address?: Maybe<Scalars['String']['output']>;
  adsText?: Maybe<Scalars['String']['output']>;
  apiGateway?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  contractCode?: Maybe<Scalars['String']['output']>;
  contractEndDate?: Maybe<Scalars['DateTime']['output']>;
  contractStartDate?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Int']['output']>;
  customerSupport?: Maybe<Scalars['String']['output']>;
  desc?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Scalars['String']['output']>;
  ftp?: Maybe<Ftp>;
  gameType?: Maybe<ContentProviderGameTypeEnum>;
  isActivate: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isG1Only: Scalars['Boolean']['output'];
  isValidGame?: Maybe<Scalars['Boolean']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  manager?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  presentor?: Maybe<Scalars['String']['output']>;
  presentorEmail?: Maybe<Scalars['String']['output']>;
  sharedProfit?: Maybe<Scalars['Float']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ContentProviderTypeEnum>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ContentProviderG1Paginate = {
  __typename?: 'ContentProviderG1Paginate';
  items: Array<ContentProviderG1>;
  meta: BasePaginationMeta;
};

export enum ContentProviderGameTypeEnum {
  EMPTY = 'EMPTY',
  G1 = 'G1',
  G1_PLUS = 'G1_PLUS',
  NOT_G1 = 'NOT_G1',
}

export type ContentProviderPaginate = {
  __typename?: 'ContentProviderPaginate';
  items: Array<ContentProvider>;
  meta: BasePaginationMeta;
};

export enum ContentProviderTypeEnum {
  ALL = 'ALL',
  G1 = 'G1',
  G1_PLUS = 'G1_PLUS',
  NOT_G1 = 'NOT_G1',
}

export type ConversionRate = {
  __typename?: 'ConversionRate';
  _id: Scalars['String']['output'];
  conversionName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Float']['output'];
  energy: Scalars['Float']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  ratio: Scalars['Float']['output'];
  token: Scalars['Float']['output'];
  type?: Maybe<ConversionRateTypeEnum>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type ConversionRateConnection = {
  __typename?: 'ConversionRateConnection';
  items?: Maybe<Array<ConversionRate>>;
  meta: BasePaginationMeta;
};

export type ConversionRateInterface = {
  __typename?: 'ConversionRateInterface';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Float']['output'];
  isDeleted: Scalars['Boolean']['output'];
  ratio: Scalars['Float']['output'];
};

export type ConversionRatePaginateDto = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export enum ConversionRateTypeEnum {
  POINT_TO_ENERGY = 'POINT_TO_ENERGY',
}

export type CreateAdsDto = {
  content: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateAdsRewardDto = {
  energyPerView?: Scalars['Int']['input'];
  viewLimit?: Scalars['Int']['input'];
};

export type CreateBannerDto = {
  createdBy?: InputMaybe<Scalars['Float']['input']>;
  image: Scalars['String']['input'];
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  url: Scalars['String']['input'];
};

export type CreateBracketInTourDto = {
  image?: InputMaybe<Scalars['String']['input']>;
  index: Scalars['String']['input'];
  matchIndex: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  nextLooserMatchId?: InputMaybe<Scalars['String']['input']>;
  nextMatchId?: InputMaybe<Scalars['String']['input']>;
  participants?: InputMaybe<Array<TeamInBracketDto>>;
  position?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<BracketInTourStateEnum>;
  tourOfflineId: Scalars['String']['input'];
  tournamentRoundText?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCategoryDto = {
  author?: InputMaybe<AuthorDto>;
  desc?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  pathThumbnail?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['Int']['input'];
  urlThumbnail?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCommentDto = {
  content: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
  relationId: Scalars['String']['input'];
  type: Scalars['Int']['input'];
};

export type CreateContactDto = {
  content: Scalars['String']['input'];
  email: Scalars['String']['input'];
  fullname: Scalars['String']['input'];
};

export type CreateContentProviderDto = {
  address?: InputMaybe<Scalars['String']['input']>;
  adsText?: InputMaybe<Scalars['String']['input']>;
  apiGateway?: InputMaybe<Scalars['String']['input']>;
  appleStoreUrl?: InputMaybe<Scalars['String']['input']>;
  avatar: Scalars['String']['input'];
  code?: InputMaybe<Scalars['String']['input']>;
  contractCode?: InputMaybe<Scalars['String']['input']>;
  contractEndDate?: InputMaybe<Scalars['String']['input']>;
  contractStartDate?: InputMaybe<Scalars['String']['input']>;
  customerSupport?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['String']['input']>;
  document?: InputMaybe<Scalars['String']['input']>;
  ftp?: InputMaybe<FtpInputDto>;
  googlePlayUrl?: InputMaybe<Scalars['String']['input']>;
  guideDesc?: InputMaybe<Scalars['String']['input']>;
  isActivate?: InputMaybe<Scalars['Boolean']['input']>;
  logo: Scalars['String']['input'];
  manager?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  presentor?: InputMaybe<Scalars['String']['input']>;
  presentorEmail?: InputMaybe<Scalars['String']['input']>;
  qrImage?: InputMaybe<Scalars['String']['input']>;
  sharedProfit?: InputMaybe<Scalars['Int']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CreateContentProviderG1Dto = {
  address?: InputMaybe<Scalars['String']['input']>;
  adsText?: InputMaybe<Scalars['String']['input']>;
  apiGateway?: InputMaybe<Scalars['String']['input']>;
  avatar: Scalars['String']['input'];
  code?: InputMaybe<Scalars['String']['input']>;
  contractCode?: InputMaybe<Scalars['String']['input']>;
  contractEndDate?: InputMaybe<Scalars['String']['input']>;
  contractStartDate?: InputMaybe<Scalars['String']['input']>;
  customerSupport?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['String']['input']>;
  document?: InputMaybe<Scalars['String']['input']>;
  ftp?: InputMaybe<FtpInputDto>;
  isActivate?: InputMaybe<Scalars['Boolean']['input']>;
  isG1Only?: InputMaybe<Scalars['Boolean']['input']>;
  logo: Scalars['String']['input'];
  manager?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  presentor?: InputMaybe<Scalars['String']['input']>;
  presentorEmail?: InputMaybe<Scalars['String']['input']>;
  sharedProfit?: InputMaybe<Scalars['Int']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CreateConversionRateDto = {
  conversionName?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['Float']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  token: Scalars['Float']['input'];
  type?: InputMaybe<ConversionRateTypeEnum>;
};

export type CreateEventTouramentDto = {
  createdBy?: InputMaybe<Scalars['Float']['input']>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTime']['input'];
  game?: InputMaybe<Array<Scalars['String']['input']>>;
  isDeleted?: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  priority?: InputMaybe<Scalars['Int']['input']>;
  prominence?: InputMaybe<Scalars['Boolean']['input']>;
  reward: Array<RewardDto>;
  shortDescription: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  status?: EventTouramentStatusEnum;
  thumbnail: Scalars['String']['input'];
  type?: InputMaybe<EventTouramentTypeEnum>;
};

export type CreateFavoriteGameDto = {
  game: Scalars['String']['input'];
  isFavorite: Scalars['Boolean']['input'];
  user?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateFeedbackDto = {
  comment: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  game: Scalars['String']['input'];
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type: TypeFeedbackEnum;
};

export type CreateFooterDto = {
  description?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  type: FooterTypeEnum;
};

export type CreateGameDto = {
  category: Array<Scalars['String']['input']>;
  contentProviderId?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['Float']['input']>;
  displayOn?: InputMaybe<DisplayOnEnum>;
  equipment?: InputMaybe<Array<Scalars['String']['input']>>;
  file: Scalars['String']['input'];
  gallery?: InputMaybe<Array<Scalars['String']['input']>>;
  imageHorizontal: Scalars['String']['input'];
  instruct: Scalars['String']['input'];
  introduce: Scalars['String']['input'];
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isRotated?: Scalars['Boolean']['input'];
  logo: Scalars['String']['input'];
  mobileType?: InputMaybe<GameMobileTypeEnum>;
  name: Scalars['String']['input'];
  prominence?: InputMaybe<Scalars['Boolean']['input']>;
  rateStar: Scalars['Float']['input'];
  status?: InputMaybe<GameStatusEnum>;
  thumbnail: Scalars['String']['input'];
  url: Scalars['String']['input'];
  urlZipGame: Scalars['String']['input'];
};

export type CreateGameG1Dto = {
  appleStoreUrl?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  contentProviderId?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  gallery?: InputMaybe<Array<Scalars['String']['input']>>;
  googlePlayUrl?: InputMaybe<Scalars['String']['input']>;
  guideDesc?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  logo: Scalars['String']['input'];
  name: Scalars['String']['input'];
  prominence?: InputMaybe<Scalars['Boolean']['input']>;
  qrImage?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<GameStatusEnum>;
};

export type CreateLikeDto = {
  game?: InputMaybe<Scalars['String']['input']>;
  isLike: Scalars['Boolean']['input'];
  userId?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateLoyaltyDto = {
  desc: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateMissionDto = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  performs: Scalars['Float']['input'];
  reward: Scalars['Float']['input'];
  status?: InputMaybe<MissionStatusEnum>;
};

export type CreateMissionV2Dto = {
  completedQuantity: Array<MissionCompletedQuantityDto>;
  cycle?: InputMaybe<CycleTypeEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  isAddBalanceNextDay?: InputMaybe<Scalars['Boolean']['input']>;
  isConsecutiveCompletedDay?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  performs?: InputMaybe<Scalars['Int']['input']>;
  rewardEnergy?: InputMaybe<Scalars['Int']['input']>;
  rewardGpoint?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<MissionV2StatusEnum>;
  type?: InputMaybe<MissionTypeEnum>;
};

export type CreateMobileIntroDto = {
  display?: InputMaybe<AdsMobileDisplay>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isProminent: Scalars['Boolean']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  localRedirect?: InputMaybe<DirectionEnum>;
  order?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateNewsDto = {
  author?: InputMaybe<AuthorDto>;
  body: Scalars['String']['input'];
  categoryIds: Array<Scalars['String']['input']>;
  coverUrl: Scalars['String']['input'];
  desc: Scalars['String']['input'];
  publishTime: Scalars['DateTime']['input'];
  tagIds: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CreatePlayerInEventDto = {
  eventTourament: Scalars['String']['input'];
  resources?: InputMaybe<Scalars['Float']['input']>;
  user?: InputMaybe<Scalars['Float']['input']>;
};

export type CreatePlayerInRoomDto = {
  resources?: InputMaybe<Scalars['Float']['input']>;
  room?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['Float']['input']>;
};

export type CreatePortalIntroDto = {
  display?: InputMaybe<AdsPortalDisplay>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isLogin?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  localRedirect?: InputMaybe<PortalDirectionEnum>;
  order?: InputMaybe<Scalars['Float']['input']>;
  prominence?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreatePromotionArtifactDto = {
  adsText?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  /** 2024-06-07T09:00:00.000Z */
  endTime: Scalars['DateTime']['input'];
  isHot?: InputMaybe<Scalars['Boolean']['input']>;
  logo: Scalars['String']['input'];
  name: Scalars['String']['input'];
  rewardedTimeRange?: InputMaybe<Array<TimeRangeDto>>;
  /** 2024-06-07T09:00:00.000Z */
  startTime: Scalars['DateTime']['input'];
  strategies: Array<UpdatePromotionStrategyArtifactDto>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePromotionCampaignDto = {
  /** 2024-06-07T09:00:00.000Z */
  endTime: Scalars['DateTime']['input'];
  isHotMobile?: InputMaybe<Scalars['Boolean']['input']>;
  logo: Scalars['String']['input'];
  name: Scalars['String']['input'];
  rewardedTimeRange?: InputMaybe<Array<TimeRangeDto>>;
  /** 2024-06-07T09:00:00.000Z */
  startTime: Scalars['DateTime']['input'];
  strategies: Array<UpdatePromotionStrategyDto>;
  url: Scalars['String']['input'];
};

export type CreatePromotionConfigDto = {
  bonus: Scalars['Float']['input'];
  code: Scalars['String']['input'];
  packageCode?: InputMaybe<Scalars['String']['input']>;
  promotionId: Scalars['ID']['input'];
  type: PromotionConfigTypeEnum;
};

export type CreatePromotionGiftDto = {
  avatar: Scalars['String']['input'];
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
  promotionId: Scalars['String']['input'];
  quantity: Scalars['Float']['input'];
};

export type CreatePromotionIntegrationDto = {
  code: Scalars['String']['input'];
  /** 2024-06-07T09:00:00.000Z */
  endTime: Scalars['DateTime']['input'];
  isHotMobile?: InputMaybe<Scalars['Boolean']['input']>;
  localRedirect?: InputMaybe<DirectionEnum>;
  logo: Scalars['String']['input'];
  name: Scalars['String']['input'];
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
  /** 2024-06-07T09:00:00.000Z */
  startTime: Scalars['DateTime']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePromotionRewardDto = {
  campaignId: Scalars['ID']['input'];
  expiredTime: Scalars['DateTime']['input'];
  mtReward: Scalars['String']['input'];
  name: Scalars['String']['input'];
  rewardNum: Scalars['Float']['input'];
  rewardedTimeRange?: InputMaybe<TimeRangeDto>;
  type: PromotionRewardTypeEnum;
};

export type CreateRegionCompanyDto = {
  benefit: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  subCommands: Array<UpdateSubCommandDto>;
  userId: Scalars['Float']['input'];
};

export type CreateRoomDto = {
  createdBy?: InputMaybe<Scalars['Float']['input']>;
  game: Scalars['String']['input'];
  roomPassword: Scalars['String']['input'];
  status?: InputMaybe<RoomStatusEnum>;
};

export type CreateSliderDto = {
  desc?: InputMaybe<Scalars['String']['input']>;
  isMobileOnly?: InputMaybe<Scalars['Boolean']['input']>;
  localRedirect?: InputMaybe<DirectionEnum>;
  order?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  type: TypeSliderEnum;
  url: Scalars['String']['input'];
  urlThumbnail: Scalars['String']['input'];
};

export type CreateSyncDataGameDto = {
  eventId?: InputMaybe<Scalars['String']['input']>;
  gameId?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['Float']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateTableRankingDto = {
  score: Scalars['Float']['input'];
  user: Scalars['Float']['input'];
};

export type CreateTagDto = {
  desc?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  type: Scalars['Int']['input'];
};

export type CreateTeamDto = {
  avatar: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  member?: InputMaybe<Array<MemberModelDto>>;
  name: Scalars['String']['input'];
};

export type CreateTeamInTourOfflineDto = {
  avatar: Scalars['String']['input'];
  members?: Array<MemberTeamInTournamentOfflineDto>;
  name: Scalars['String']['input'];
  status?: InputMaybe<TeamInTourStatusEnum>;
  tourOffline: Scalars['ID']['input'];
};

export type CreateTourOfflineDto = {
  competitionFormat: CompetitionFormatDto;
  createdBy?: InputMaybe<Scalars['Float']['input']>;
  description: Scalars['String']['input'];
  endDate: Scalars['DateTime']['input'];
  game?: InputMaybe<Scalars['String']['input']>;
  isFinished?: InputMaybe<Scalars['Boolean']['input']>;
  linkRegisterJoin: Scalars['String']['input'];
  memberInTeams?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  numberTeams: Scalars['Float']['input'];
  organizer?: InputMaybe<Scalars['String']['input']>;
  shortDescription: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  status?: TourOfflineStatusEnum;
  thumbnail: Scalars['String']['input'];
  typeTournament: TypeTournament;
};

export type CreateTournamentOfflineNewsDto = {
  body: Scalars['String']['input'];
  title: Scalars['String']['input'];
  tournamentOffline: Scalars['String']['input'];
};

export type CreateUserTaskDto = {
  task: Array<TaskDto>;
  user: Scalars['Float']['input'];
};

export type CreateVideoDto = {
  author?: InputMaybe<AuthorDto>;
  categoryIds: Array<Scalars['String']['input']>;
  desc: Scalars['String']['input'];
  name: Scalars['String']['input'];
  /** 2024-05-24 12:00:00 */
  publishTime: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  url720p?: InputMaybe<Scalars['String']['input']>;
  url1080p?: InputMaybe<Scalars['String']['input']>;
  urlFile?: InputMaybe<Scalars['String']['input']>;
  urlThumbnail?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVideoTournamentOfflineDto = {
  bracketId: Scalars['String']['input'];
  link: Scalars['String']['input'];
  tournametId: Scalars['String']['input'];
  type?: InputMaybe<TypeVideoTournamentOfflineEnum>;
};

export type CreatorInfo = {
  __typename?: 'CreatorInfo';
  createAt?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['Float']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isdn?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  realName?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export enum CycleTypeEnum {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export type DataChecked = {
  __typename?: 'DataChecked';
  accId?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['String']['output']>;
  balance?: Maybe<Scalars['String']['output']>;
  campaignId?: Maybe<Scalars['String']['output']>;
  debitAmount?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  ord?: Maybe<Scalars['String']['output']>;
  productType?: Maybe<ProductTypeEnum>;
  reqType?: Maybe<Scalars['String']['output']>;
  requestId?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['String']['output']>;
  topupAccount?: Maybe<Scalars['String']['output']>;
  topupType?: Maybe<Scalars['String']['output']>;
  tracenumber?: Maybe<Scalars['String']['output']>;
  transtime?: Maybe<Scalars['String']['output']>;
};

export enum DirectionEnum {
  FREETOUR = 'FREETOUR',
  NONE = 'NONE',
  PAIDTOUR = 'PAIDTOUR',
  STORE = 'STORE',
  TOURRANKING = 'TOURRANKING',
  TOURTESTING = 'TOURTESTING',
}

export enum DisplayOnEnum {
  ALL = 'ALL',
  MOBILE = 'MOBILE',
  WEBSITE = 'WEBSITE',
}

export type DoubleBracketData = {
  __typename?: 'DoubleBracketData';
  lower?: Maybe<Array<BracketInTourInterface>>;
  upper?: Maybe<Array<BracketInTourInterface>>;
};

export type EventTourament = {
  __typename?: 'EventTourament';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  description: Scalars['String']['output'];
  endDate: Scalars['DateTime']['output'];
  game?: Maybe<Array<Scalars['String']['output']>>;
  gameInfo?: Maybe<Array<Game>>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  priority?: Maybe<Scalars['Float']['output']>;
  prominence?: Maybe<Scalars['Boolean']['output']>;
  reward: Array<Reward>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  star?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['DateTime']['output'];
  status: EventTouramentStatusEnum;
  thumbnail: Scalars['String']['output'];
  totalPlayer?: Maybe<Scalars['Float']['output']>;
  type: EventTouramentTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type EventTouramentConnection = {
  __typename?: 'EventTouramentConnection';
  items?: Maybe<Array<EventTourament>>;
  meta: BasePaginationMeta;
};

export type EventTouramentPaginateDto = {
  /** 2024-09-10 */
  endTime?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  /** 2024-09-10 */
  startTime?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EventTouramentStatusEnum>;
  type?: InputMaybe<EventTouramentTypeEnum>;
};

export enum EventTouramentRewardEnum {
  CASH = 'CASH',
  ENERGY = 'ENERGY',
  POINT = 'POINT',
}

export enum EventTouramentStatusEnum {
  FINISHED = 'FINISHED',
  HAPPENING = 'HAPPENING',
  UPCOMING = 'UPCOMING',
}

export enum EventTouramentTypeEnum {
  FREE = 'FREE',
  PREMIUM = 'PREMIUM',
}

export type Ftp = {
  __typename?: 'FTP';
  host?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type FavoriteGame = {
  __typename?: 'FavoriteGame';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  game?: Maybe<Scalars['String']['output']>;
  gameInfo?: Maybe<Game>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isFavorite: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  user: Scalars['Float']['output'];
};

export type FavoriteGameV2 = {
  __typename?: 'FavoriteGameV2';
  gameInfo?: Maybe<Game>;
  gameRefId: Scalars['String']['output'];
  isFavorite: Scalars['Boolean']['output'];
  userRefId: Scalars['Int']['output'];
};

export type FavoriteGameV2Dto = {
  gameId: Scalars['String']['input'];
  isFavorite: Scalars['Boolean']['input'];
};

export type FavoriteInfo = {
  __typename?: 'FavoriteInfo';
  game?: Maybe<Scalars['String']['output']>;
  isFavorite?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<Scalars['Float']['output']>;
};

export type FavoriteInterface = {
  __typename?: 'FavoriteInterface';
  isFavorite: Scalars['Boolean']['output'];
};

export type Feedback = {
  __typename?: 'Feedback';
  _id: Scalars['String']['output'];
  comment: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  game: Scalars['String']['output'];
  gameInfo?: Maybe<Game>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type FeedbackConnection = {
  __typename?: 'FeedbackConnection';
  items?: Maybe<Array<Feedback>>;
  meta: BasePaginationMeta;
};

export type FeedbackPaginateDto = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  game?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Footer = {
  __typename?: 'Footer';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  type: FooterTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type FooterPaginate = {
  __typename?: 'FooterPaginate';
  items: Array<Footer>;
  meta: BasePaginationMeta;
};

export type FooterPaginateDto = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<FooterTypeEnum>;
};

export enum FooterTypeEnum {
  LINK_APP_STORE = 'LINK_APP_STORE',
  LINK_FACEBOOK = 'LINK_FACEBOOK',
  LINK_GOOGLE_PLAY = 'LINK_GOOGLE_PLAY',
  LINK_TIKTOK = 'LINK_TIKTOK',
  LINK_YOUTUBE = 'LINK_YOUTUBE',
}

export type FtpInputDto = {
  host: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type Game = {
  __typename?: 'Game';
  _id: Scalars['String']['output'];
  category?: Maybe<Array<Scalars['String']['output']>>;
  categoryInfo?: Maybe<Array<CategoryGraphQl>>;
  contentProviderId?: Maybe<Scalars['String']['output']>;
  cpAdsText?: Maybe<Scalars['String']['output']>;
  cpName?: Maybe<Scalars['String']['output']>;
  cpType?: Maybe<ContentProviderTypeEnum>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Float']['output'];
  displayOn?: Maybe<DisplayOnEnum>;
  equipment: Array<Scalars['String']['output']>;
  file: Scalars['String']['output'];
  gallery?: Maybe<Array<Scalars['String']['output']>>;
  imageHorizontal: Scalars['String']['output'];
  instruct: Scalars['String']['output'];
  introduce: Scalars['String']['output'];
  isCpActivate?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isFavorite: Scalars['Boolean']['output'];
  isRotated?: Maybe<Scalars['Boolean']['output']>;
  logo: Scalars['String']['output'];
  mobileType?: Maybe<GameMobileTypeEnum>;
  name: Scalars['String']['output'];
  prominence?: Maybe<Scalars['Boolean']['output']>;
  rateStar?: Maybe<Scalars['Float']['output']>;
  status: GameStatusEnum;
  thumbnail: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  url: Scalars['String']['output'];
  urlZipGame: Scalars['String']['output'];
};

export type GameConfigurationTourOffline = {
  __typename?: 'GameConfigurationTourOffline';
  formula?: Maybe<TourOfflineFormulaEnum>;
  method?: Maybe<MethodContinueEnum>;
  methodWinLose?: Maybe<TourOfflineMethodWinLoseEnum>;
  rank?: Maybe<TourOfflineRankedEnum>;
  turn?: Maybe<TourOfflineTurnEnum>;
};

export type GameConnection = {
  __typename?: 'GameConnection';
  items: Array<Game>;
  meta: BasePaginationMeta;
};

export type GameG1 = {
  __typename?: 'GameG1';
  _id: Scalars['String']['output'];
  appleStoreUrl?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Array<Scalars['String']['output']>>;
  categoryInfo?: Maybe<Array<CategoryGraphQl>>;
  contentProviderId?: Maybe<Scalars['String']['output']>;
  cpName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Float']['output'];
  description?: Maybe<Scalars['String']['output']>;
  favorite?: Maybe<FavoriteInterface>;
  gallery?: Maybe<Array<Scalars['String']['output']>>;
  googlePlayUrl?: Maybe<Scalars['String']['output']>;
  guideDesc?: Maybe<Scalars['String']['output']>;
  isCpActivate?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  logo: Scalars['String']['output'];
  name: Scalars['String']['output'];
  prominence?: Maybe<Scalars['Boolean']['output']>;
  qrImage?: Maybe<Scalars['String']['output']>;
  status: GameStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type GameG1Paginate = {
  __typename?: 'GameG1Paginate';
  items: Array<GameG1>;
  meta: BasePaginationMeta;
};

export type GameG1PaginateDto = {
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  contentProviderId?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  prominence?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<GameStatusEnum>;
};

export enum GameMobileTypeEnum {
  NEWEST = 'NEWEST',
  NORMAL = 'NORMAL',
  PROMINENCE = 'PROMINENCE',
}

export type GamePaginateDto = {
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  contentProviderId?: InputMaybe<Scalars['String']['input']>;
  displayOn?: InputMaybe<DisplayOnEnum>;
  except?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  prominence?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<GameStatusEnum>;
};

export enum GameStatusEnum {
  PENDING = 'PENDING',
  PUBLISHED = 'PUBLISHED',
  REFUSE = 'REFUSE',
  WAITING = 'WAITING',
}

export type GeneratePromotionCodeDto = {
  isdn: Scalars['String']['input'];
  packageCode: Scalars['String']['input'];
  registerType: Scalars['String']['input'];
  types: Array<PromotionCodeTypeEnum>;
};

export type Like = {
  __typename?: 'Like';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  game?: Maybe<Scalars['String']['output']>;
  isLike: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  userId: Scalars['Float']['output'];
};

export type LikesInfo = {
  __typename?: 'LikesInfo';
  game?: Maybe<Scalars['String']['output']>;
  isLike?: Maybe<Scalars['Boolean']['output']>;
  totalLike?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type Log = {
  __typename?: 'Log';
  _id: Scalars['String']['output'];
  action: LogActionEnum;
  actionByUsername: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  documentId?: Maybe<Scalars['String']['output']>;
  nameCollection: Scalars['String']['output'];
  newValue: Scalars['String']['output'];
  oldValue?: Maybe<Scalars['String']['output']>;
  time: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export enum LogActionEnum {
  APPROVE = 'APPROVE',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

export type LogPaginate = {
  __typename?: 'LogPaginate';
  items: Array<Log>;
  meta: BasePaginationMeta;
};

export type LogPaginateDto = {
  action?: InputMaybe<LogActionEnum>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LogSyncGifts = {
  __typename?: 'LogSyncGifts';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  expireTime: Scalars['DateTime']['output'];
  giftCode: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  promotionCode: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
  requestId?: Maybe<Scalars['String']['output']>;
  state: StateUserReceivedGiftEnum;
  time: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  userRefId: Scalars['String']['output'];
};

export type MemberModel = {
  __typename?: 'MemberModel';
  avatar: Scalars['String']['output'];
  dateOfBirth: Scalars['DateTime']['output'];
  introduce: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type MemberModelDto = {
  avatar: Scalars['String']['input'];
  dateOfBirth: Scalars['DateTime']['input'];
  introduce: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MemberTeamInTournamentOffline = {
  __typename?: 'MemberTeamInTournamentOffline';
  name: Scalars['String']['output'];
  uid: Scalars['Float']['output'];
};

export type MemberTeamInTournamentOfflineDto = {
  name: Scalars['String']['input'];
  uid: Scalars['Float']['input'];
};

export enum MethodContinueEnum {
  DIFFERENCE = 'DIFFERENCE',
  DRAWLOTS = 'DRAWLOTS',
  FIGHTBACK = 'FIGHTBACK',
}

export type Mission = {
  __typename?: 'Mission';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Float']['output'];
  description?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  performs: Scalars['Float']['output'];
  reward: Scalars['Float']['output'];
  status: MissionStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type MissionCompletedQuantity = {
  __typename?: 'MissionCompletedQuantity';
  quantity?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<ConsumptionMissionTypeEnum>;
};

export type MissionCompletedQuantityDto = {
  quantity?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<ConsumptionMissionTypeEnum>;
};

export type MissionConnection = {
  __typename?: 'MissionConnection';
  items?: Maybe<Array<Mission>>;
};

export enum MissionStatusEnum {
  PENDING = 'PENDING',
  PUBLISHED = 'PUBLISHED',
  REFUSE = 'REFUSE',
  WAITING = 'WAITING',
}

export enum MissionTypeEnum {
  CONSUMPTION_ENERGY = 'CONSUMPTION_ENERGY',
  CONSUMPTION_GPOINT = 'CONSUMPTION_GPOINT',
  INDIVIDUAL_PACKAGE = 'INDIVIDUAL_PACKAGE',
  LOGIN = 'LOGIN',
  MONTH_SUB_PACKAGE = 'MONTH_SUB_PACKAGE',
  PLAY = 'PLAY',
  SHARE = 'SHARE',
  TOUR_FREE = 'TOUR_FREE',
  TOUR_PREMIUM = 'TOUR_PREMIUM',
  WATCH_VIDEO = 'WATCH_VIDEO',
  WEEKLY_SUB_PACKAGE = 'WEEKLY_SUB_PACKAGE',
}

export type MissionV2 = {
  __typename?: 'MissionV2';
  _id: Scalars['String']['output'];
  completedQuantity?: Maybe<Array<MissionCompletedQuantity>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  cycle: CycleTypeEnum;
  description?: Maybe<Scalars['String']['output']>;
  isAddBalanceNextDay: Scalars['Boolean']['output'];
  isConsecutiveCompletedDay?: Maybe<Scalars['Boolean']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  performs?: Maybe<Scalars['Int']['output']>;
  rewardEnergy?: Maybe<Scalars['Int']['output']>;
  rewardGpoint?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<MissionV2StatusEnum>;
  type?: Maybe<MissionTypeEnum>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export type MissionV2Paginate = {
  __typename?: 'MissionV2Paginate';
  items?: Maybe<Array<MissionV2>>;
  meta: BasePaginationMeta;
};

export type MissionV2PaginateDto = {
  cycle?: InputMaybe<CycleTypeEnum>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<MissionV2StatusEnum>;
};

export enum MissionV2StatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type MobileIntro = {
  __typename?: 'MobileIntro';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  display?: Maybe<AdsMobileDisplay>;
  endTime?: Maybe<Scalars['DateTime']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isProminent?: Maybe<Scalars['Boolean']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  localRedirect?: Maybe<DirectionEnum>;
  order?: Maybe<Scalars['Float']['output']>;
  startTime?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type MobileIntroPaginate = {
  __typename?: 'MobileIntroPaginate';
  items: Array<MobileIntro>;
  meta: BasePaginationMeta;
};

export type MobileIntroPaginateDto = {
  display?: InputMaybe<AdsMobileDisplay>;
  isValid?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  approveComment: CommentGraphQl;
  approveNew: News;
  approveTournamentOfflineNews: TournamentOfflineNewsGraphQl;
  approveVideo: Video;
  complatedRewards: Scalars['Boolean']['output'];
  createAds: Ads;
  createAdsReward: AdsReward;
  createBanner: Banner;
  createBracket: Array<BracketInTour>;
  createBracketTourOffline: BracketInTour;
  createCategory: CategoryGraphQl;
  createComment: CommentGraphQl;
  createConfigPromotion: PromotionConfig;
  createContact: Contact;
  createContentProvider: ContentProvider;
  createContentProviderG1: ContentProviderG1;
  createConversionRate: ConversionRate;
  createDoubleEliminationBracket: Array<BracketInTour>;
  createEventTourament: EventTourament;
  createFavoriteGame: FavoriteGame;
  createFeedback: Feedback;
  createFooter: Footer;
  createGame: Game;
  createGameG1: GameG1;
  createGiftPromotion: PromotionGift;
  createLike: Like;
  createLoyalty: Loyalty;
  createMission: Mission;
  createMissionV2: MissionV2;
  createMobileIntro: MobileIntro;
  createNew: News;
  createOrUpdateFavoriteGameV2: FavoriteGameV2;
  createPlayerInEvent: PlayerInEvent;
  createPlayerInRoom: PlayerInRoom;
  createPortalIntro: PortalIntro;
  createPromotionArtifact: PromotionArtifact;
  createPromotionArtifactReward: PromotionReward;
  createPromotionCampaign: PromotionCampaign;
  createPromotionIntegration: PromotionIntegration;
  createRate: Rate;
  createRegionCompany: RegionCompany;
  createRoom: Room;
  createScoringCircle: Array<BracketInTour>;
  createSlider: Slider;
  createTag: TagGraphQl;
  createTaskUser: UserTask;
  createTeam: Team;
  createTeamInTourOffline: TeamInTourOffline;
  createTourOffline: TourOffline;
  createTournamentOfflineNews: TournamentOfflineNewsGraphQl;
  createVideo: Video;
  createVideoTournamentOffline: VideoTournamentOffline;
  deleteCategory: CategoryGraphQl;
  deleteComment: CommentGraphQl;
  deleteConfigPromotion: Remove;
  deleteContentProvider: Remove;
  deleteContentProviderG1: Remove;
  deleteConversionRate: Remove;
  deleteFeedback: Remove;
  deleteGame: Remove;
  deleteGameG1: Remove;
  deleteGiftPromotion: Remove;
  deleteLoyalty: Loyalty;
  deleteMission: Remove;
  deleteMissionV2: Remove;
  deleteMobileIntro: Remove;
  deleteNew: News;
  deletePortalIntro: Remove;
  deletePromotionArtifact: Remove;
  deletePromotionArtifactReward: Remove;
  deletePromotionCampaign: Remove;
  deletePromotionIntegration: Remove;
  deleteRegionCompany: Remove;
  deleteSlider: Remove;
  deleteTag: TagGraphQl;
  deleteTeamInTourOffline: Remove;
  deleteTourOffline: Remove;
  deleteTournamentOfflineNews: TournamentOfflineNewsGraphQl;
  deleteVideo: Video;
  deleteVideoTournamentOffline: Remove;
  migrateCategories: Array<CategoryGraphQl>;
  migrateGame: Array<Game>;
  migrateMobileIntro: Array<MobileIntro>;
  migratePortalIntro: Array<PortalIntro>;
  migratePromotionIntegration: Array<PromotionIntegration>;
  migrateSliders: Array<Slider>;
  pinVideo: Video;
  removeEventTourament: Remove;
  removeFavoriteGame: FavoriteGame;
  removeLike: Like;
  removePlayerInEvent: Remove;
  removePlayerInRoom: Remove;
  removeRoom: Remove;
  removeTeam: Remove;
  syncDataGame: SyncDataGameInterface;
  syncTableRanking: TableRanking;
  testGeneratePromotionArtifactCode: PromotionCode;
  updateAds: Ads;
  updateAdsReward: AdsReward;
  updateBanner: Banner;
  updateCategory: CategoryGraphQl;
  updateComment: CommentGraphQl;
  updateConfigPromotion: PromotionConfig;
  updateContentProvider: ContentProvider;
  updateContentProviderG1: ContentProviderG1;
  updateConversionRate: ConversionRate;
  updateEventTourament: EventTourament;
  updateFeedback: Feedback;
  updateFooter: Footer;
  updateGame: Game;
  updateGameG1: GameG1;
  updateGiftPromotion: PromotionGift;
  updateInteraction: Video;
  updateInteractionNews: News;
  updateLoyalty: Loyalty;
  updateMatch: BracketInTour;
  updateMission: Mission;
  updateMissionV2: MissionV2;
  updateMobileIntro: MobileIntro;
  updateNew: News;
  updatePortalIntro: PortalIntro;
  updatePromotionArtifact: PromotionArtifact;
  updatePromotionArtifactCode: PromotionCode;
  updatePromotionArtifactReward: PromotionReward;
  updatePromotionCampaign: PromotionCampaign;
  updatePromotionIntegration: PromotionIntegration;
  updateRate: Rate;
  updateRegionCompany: RegionCompany;
  updateSlider: Slider;
  updateTag: TagGraphQl;
  updateTaskUser: UserTask;
  updateTeam: Team;
  updateTeamInTourOffline: TeamInTourOffline;
  updateTourOffline: TourOffline;
  updateTournamentOfflineNews: TournamentOfflineNewsGraphQl;
  updateVideo: Video;
  updateVideoTournamentOffline: VideoTournamentOffline;
};

export type MutationApproveCommentArgs = {
  data: ApproveCommentDto;
};

export type MutationApproveNewArgs = {
  input: ApproveNewsDto;
};

export type MutationApproveTournamentOfflineNewsArgs = {
  data: ApproveTournamentOfflineNewsDto;
};

export type MutationApproveVideoArgs = {
  input: ApproveVideoDto;
};

export type MutationComplatedRewardsArgs = {
  input: CompletionRewardDto;
};

export type MutationCreateAdsArgs = {
  input: CreateAdsDto;
};

export type MutationCreateAdsRewardArgs = {
  input: CreateAdsRewardDto;
};

export type MutationCreateBannerArgs = {
  input: CreateBannerDto;
};

export type MutationCreateBracketArgs = {
  tourOffline: Scalars['String']['input'];
};

export type MutationCreateBracketTourOfflineArgs = {
  input: CreateBracketInTourDto;
};

export type MutationCreateCategoryArgs = {
  data: CreateCategoryDto;
};

export type MutationCreateCommentArgs = {
  data: CreateCommentDto;
};

export type MutationCreateConfigPromotionArgs = {
  input: CreatePromotionConfigDto;
};

export type MutationCreateContactArgs = {
  input: CreateContactDto;
};

export type MutationCreateContentProviderArgs = {
  input: CreateContentProviderDto;
};

export type MutationCreateContentProviderG1Args = {
  input: CreateContentProviderG1Dto;
};

export type MutationCreateConversionRateArgs = {
  input: CreateConversionRateDto;
};

export type MutationCreateDoubleEliminationBracketArgs = {
  tourOffline: Scalars['String']['input'];
};

export type MutationCreateEventTouramentArgs = {
  input: CreateEventTouramentDto;
};

export type MutationCreateFavoriteGameArgs = {
  input: CreateFavoriteGameDto;
};

export type MutationCreateFeedbackArgs = {
  input: CreateFeedbackDto;
};

export type MutationCreateFooterArgs = {
  input: CreateFooterDto;
};

export type MutationCreateGameArgs = {
  input: CreateGameDto;
};

export type MutationCreateGameG1Args = {
  input: CreateGameG1Dto;
};

export type MutationCreateGiftPromotionArgs = {
  input: CreatePromotionGiftDto;
};

export type MutationCreateLikeArgs = {
  input: CreateLikeDto;
};

export type MutationCreateLoyaltyArgs = {
  data: CreateLoyaltyDto;
};

export type MutationCreateMissionArgs = {
  input: CreateMissionDto;
};

export type MutationCreateMissionV2Args = {
  input: CreateMissionV2Dto;
};

export type MutationCreateMobileIntroArgs = {
  input: CreateMobileIntroDto;
};

export type MutationCreateNewArgs = {
  input: CreateNewsDto;
};

export type MutationCreateOrUpdateFavoriteGameV2Args = {
  input: FavoriteGameV2Dto;
};

export type MutationCreatePlayerInEventArgs = {
  input: CreatePlayerInEventDto;
};

export type MutationCreatePlayerInRoomArgs = {
  input: CreatePlayerInRoomDto;
};

export type MutationCreatePortalIntroArgs = {
  input: CreatePortalIntroDto;
};

export type MutationCreatePromotionArtifactArgs = {
  input: CreatePromotionArtifactDto;
};

export type MutationCreatePromotionArtifactRewardArgs = {
  input: CreatePromotionRewardDto;
};

export type MutationCreatePromotionCampaignArgs = {
  input: CreatePromotionCampaignDto;
};

export type MutationCreatePromotionIntegrationArgs = {
  input: CreatePromotionIntegrationDto;
};

export type MutationCreateRateArgs = {
  input: RateDto;
};

export type MutationCreateRegionCompanyArgs = {
  input: CreateRegionCompanyDto;
};

export type MutationCreateRoomArgs = {
  input: CreateRoomDto;
};

export type MutationCreateScoringCircleArgs = {
  tourOffline: Scalars['String']['input'];
};

export type MutationCreateSliderArgs = {
  data: CreateSliderDto;
};

export type MutationCreateTagArgs = {
  data: CreateTagDto;
};

export type MutationCreateTaskUserArgs = {
  input: CreateUserTaskDto;
};

export type MutationCreateTeamArgs = {
  input: CreateTeamDto;
};

export type MutationCreateTeamInTourOfflineArgs = {
  input: CreateTeamInTourOfflineDto;
};

export type MutationCreateTourOfflineArgs = {
  input: CreateTourOfflineDto;
};

export type MutationCreateTournamentOfflineNewsArgs = {
  data: CreateTournamentOfflineNewsDto;
};

export type MutationCreateVideoArgs = {
  input: CreateVideoDto;
};

export type MutationCreateVideoTournamentOfflineArgs = {
  input: CreateVideoTournamentOfflineDto;
};

export type MutationDeleteCategoryArgs = {
  _id: Scalars['String']['input'];
};

export type MutationDeleteCommentArgs = {
  _id: Scalars['String']['input'];
};

export type MutationDeleteConfigPromotionArgs = {
  _id: Scalars['String']['input'];
};

export type MutationDeleteContentProviderArgs = {
  _id: Scalars['String']['input'];
};

export type MutationDeleteContentProviderG1Args = {
  _id: Scalars['String']['input'];
};

export type MutationDeleteConversionRateArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteFeedbackArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteGameArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteGameG1Args = {
  id: Scalars['String']['input'];
};

export type MutationDeleteGiftPromotionArgs = {
  _id: Scalars['String']['input'];
};

export type MutationDeleteLoyaltyArgs = {
  _id: Scalars['String']['input'];
};

export type MutationDeleteMissionArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteMissionV2Args = {
  id: Scalars['String']['input'];
};

export type MutationDeleteMobileIntroArgs = {
  _id: Scalars['String']['input'];
};

export type MutationDeleteNewArgs = {
  _id: Scalars['String']['input'];
};

export type MutationDeletePortalIntroArgs = {
  _id: Scalars['String']['input'];
};

export type MutationDeletePromotionArtifactArgs = {
  _id: Scalars['String']['input'];
};

export type MutationDeletePromotionArtifactRewardArgs = {
  _id: Scalars['String']['input'];
};

export type MutationDeletePromotionCampaignArgs = {
  _id: Scalars['String']['input'];
};

export type MutationDeletePromotionIntegrationArgs = {
  _id: Scalars['String']['input'];
};

export type MutationDeleteRegionCompanyArgs = {
  _id: Scalars['String']['input'];
};

export type MutationDeleteSliderArgs = {
  _id: Scalars['String']['input'];
};

export type MutationDeleteTagArgs = {
  _id: Scalars['String']['input'];
};

export type MutationDeleteTeamInTourOfflineArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteTourOfflineArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteTournamentOfflineNewsArgs = {
  _id: Scalars['String']['input'];
};

export type MutationDeleteVideoArgs = {
  _id: Scalars['String']['input'];
};

export type MutationDeleteVideoTournamentOfflineArgs = {
  id: Scalars['String']['input'];
};

export type MutationPinVideoArgs = {
  input: PinVideoDto;
};

export type MutationRemoveEventTouramentArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveFavoriteGameArgs = {
  input: UpdateFavoriteGameDto;
};

export type MutationRemoveLikeArgs = {
  input: UpdateLikeDto;
};

export type MutationRemovePlayerInEventArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemovePlayerInRoomArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveRoomArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveTeamArgs = {
  id: Scalars['String']['input'];
};

export type MutationSyncDataGameArgs = {
  input: CreateSyncDataGameDto;
};

export type MutationSyncTableRankingArgs = {
  input: CreateTableRankingDto;
};

export type MutationTestGeneratePromotionArtifactCodeArgs = {
  input: GeneratePromotionCodeDto;
};

export type MutationUpdateAdsArgs = {
  input: UpdateAdsDto;
};

export type MutationUpdateAdsRewardArgs = {
  input: UpdateAdsRewardDto;
};

export type MutationUpdateBannerArgs = {
  input: UpdateBannerDto;
};

export type MutationUpdateCategoryArgs = {
  data: UpdateCategoryDto;
};

export type MutationUpdateCommentArgs = {
  data: UpdateCommentDto;
};

export type MutationUpdateConfigPromotionArgs = {
  input: UpdatePromotionConfigDto;
};

export type MutationUpdateContentProviderArgs = {
  input: UpdateContentProviderDto;
};

export type MutationUpdateContentProviderG1Args = {
  input: UpdateContentProviderG1Dto;
};

export type MutationUpdateConversionRateArgs = {
  input: UpdateConversionRateDto;
};

export type MutationUpdateEventTouramentArgs = {
  input: UpdateEventTouramentDto;
};

export type MutationUpdateFeedbackArgs = {
  input: UpdateFeedbackDto;
};

export type MutationUpdateFooterArgs = {
  input: UpdateFooterDto;
};

export type MutationUpdateGameArgs = {
  input: UpdateGameDto;
};

export type MutationUpdateGameG1Args = {
  input: UpdateGameG1Dto;
};

export type MutationUpdateGiftPromotionArgs = {
  input: UpdatePromotionGiftDto;
};

export type MutationUpdateInteractionArgs = {
  input: UpdateVideoDto;
};

export type MutationUpdateInteractionNewsArgs = {
  input: UpdateNewsDto;
};

export type MutationUpdateLoyaltyArgs = {
  data: UpdateLoyaltyDto;
};

export type MutationUpdateMatchArgs = {
  input: UpdateBracketInTourDto;
};

export type MutationUpdateMissionArgs = {
  input: UpdateMissionDto;
};

export type MutationUpdateMissionV2Args = {
  input: UpdateMissionV2Dto;
};

export type MutationUpdateMobileIntroArgs = {
  input: UpdateMobileIntroDto;
};

export type MutationUpdateNewArgs = {
  input: UpdateNewsDto;
};

export type MutationUpdatePortalIntroArgs = {
  input: UpdatePortalIntroDto;
};

export type MutationUpdatePromotionArtifactArgs = {
  input: UpdatePromotionArtifactDto;
};

export type MutationUpdatePromotionArtifactCodeArgs = {
  input: UpdatePromotionCodeDto;
};

export type MutationUpdatePromotionArtifactRewardArgs = {
  input: UpdatePromotionRewardDto;
};

export type MutationUpdatePromotionCampaignArgs = {
  input: UpdatePromotionCampaignDto;
};

export type MutationUpdatePromotionIntegrationArgs = {
  input: UpdatePromotionIntegrationDto;
};

export type MutationUpdateRateArgs = {
  input: UpdateRateDto;
};

export type MutationUpdateRegionCompanyArgs = {
  input: UpdateRegionCompanyDto;
};

export type MutationUpdateSliderArgs = {
  data: UpdateSliderDto;
};

export type MutationUpdateTagArgs = {
  data: UpdateTagDto;
};

export type MutationUpdateTaskUserArgs = {
  input: UpdateUserTaskDto;
};

export type MutationUpdateTeamArgs = {
  input: UpdateTeamDto;
};

export type MutationUpdateTeamInTourOfflineArgs = {
  input: UpdateTeamInTourOfflineDto;
};

export type MutationUpdateTourOfflineArgs = {
  input: UpdateTourOfflineDto;
};

export type MutationUpdateTournamentOfflineNewsArgs = {
  data: UpdateTournamentOfflineNewsDto;
};

export type MutationUpdateVideoArgs = {
  input: UpdateVideoDto;
};

export type MutationUpdateVideoTournamentOfflineArgs = {
  input: UpdateVideoTournamentOfflineDto;
};

export type News = {
  __typename?: 'News';
  _id: Scalars['String']['output'];
  alias?: Maybe<Scalars['String']['output']>;
  author?: Maybe<User>;
  body: Scalars['String']['output'];
  categories?: Maybe<Array<CategoryGraphQl>>;
  categoryIds: Array<Scalars['String']['output']>;
  comment: Scalars['Float']['output'];
  coverUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  desc: Scalars['String']['output'];
  dislike?: Maybe<Scalars['Int']['output']>;
  dislikes?: Maybe<Array<User>>;
  isDeleted: Scalars['Boolean']['output'];
  isHot: Scalars['Boolean']['output'];
  like?: Maybe<Scalars['Int']['output']>;
  likes?: Maybe<Array<User>>;
  publishTime: Scalars['DateTime']['output'];
  shared: Scalars['Int']['output'];
  status: NewsStatusEnum;
  tagIds: Array<Scalars['String']['output']>;
  tags?: Maybe<Array<TagGraphQl>>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  view: Scalars['Int']['output'];
};

export type NewsPaginate = {
  __typename?: 'NewsPaginate';
  items: Array<News>;
  meta: BasePaginationMeta;
};

export type NewsPaginateDto = {
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  isHot?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NewsResultGraphQl = {
  __typename?: 'NewsResultGraphQL';
  items: Array<NewsSearchGraphQl>;
  meta: BasePaginationMeta;
};

export type NewsSearchGraphQl = {
  __typename?: 'NewsSearchGraphQL';
  _id: Scalars['String']['output'];
  alias?: Maybe<Scalars['String']['output']>;
  author?: Maybe<User>;
  body: Scalars['String']['output'];
  categories: Array<CategoryGraphQl>;
  categoryIds: Array<Scalars['String']['output']>;
  comment: Scalars['Float']['output'];
  coverUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  desc: Scalars['String']['output'];
  dislike?: Maybe<Scalars['Int']['output']>;
  dislikes?: Maybe<Array<User>>;
  isDeleted: Scalars['Boolean']['output'];
  isHot: Scalars['Boolean']['output'];
  like?: Maybe<Scalars['Int']['output']>;
  likes?: Maybe<Array<User>>;
  publishTime: Scalars['DateTime']['output'];
  shared: Scalars['Int']['output'];
  status: NewsStatusEnum;
  tagIds: Array<Scalars['String']['output']>;
  tags?: Maybe<Array<TagGraphQl>>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  view: Scalars['Int']['output'];
};

export enum NewsStatusEnum {
  PENDING = 'PENDING',
  PUBLISHED = 'PUBLISHED',
  REFUSE = 'REFUSE',
  WAITING = 'WAITING',
}

export type Paging = {
  __typename?: 'Paging';
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PayRewardsGraphQl = {
  __typename?: 'PayRewardsGraphQL';
  _id: Scalars['String']['output'];
  accId: Scalars['String']['output'];
  amount?: Maybe<Scalars['String']['output']>;
  balance?: Maybe<Scalars['String']['output']>;
  campaignId?: Maybe<Scalars['ID']['output']>;
  checktrans?: Maybe<Array<CheckTransPayRewards>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  debitAmount?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  ord?: Maybe<Scalars['String']['output']>;
  productType?: Maybe<ProductTypeEnum>;
  reqType: Scalars['String']['output'];
  requestId: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['String']['output']>;
  topupAccount: Scalars['String']['output'];
  topupType?: Maybe<Scalars['String']['output']>;
  tracenumber?: Maybe<Scalars['String']['output']>;
  transtime?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type PayRewardsPaginate = {
  __typename?: 'PayRewardsPaginate';
  items: Array<PayRewardsGraphQl>;
  meta: BasePaginationMeta;
};

export type PayRewardsPaginateDto = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<Scalars['Float']['input']>;
};

export type PinVideoDto = {
  _id: Scalars['ID']['input'];
  isPin?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PlayerInEvent = {
  __typename?: 'PlayerInEvent';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  creatorInfo?: Maybe<CreatorInfo>;
  eventTourament?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  resources?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  user?: Maybe<Scalars['Int']['output']>;
};

export type PlayerInEventPaginateDto = {
  eventId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  time?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PlayerInEventTournament = {
  __typename?: 'PlayerInEventTournament';
  dob?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  star: Scalars['Int']['output'];
  timeJoined: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};

export type PlayerInEventTournamentPaginate = {
  __typename?: 'PlayerInEventTournamentPaginate';
  items: Array<PlayerInEventTournament>;
  meta: BasePaginationMeta;
};

export type PlayerInRoom = {
  __typename?: 'PlayerInRoom';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  creatorInfo?: Maybe<CreatorInfo>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  resources?: Maybe<Scalars['String']['output']>;
  room?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  user?: Maybe<Scalars['Float']['output']>;
};

export type PlayerInRoomConnection = {
  __typename?: 'PlayerInRoomConnection';
  items?: Maybe<Array<PlayerInRoom>>;
  meta: BasePaginationMeta;
};

export type PlayerInRoomPaginateDto = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  time?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PlayerResult = {
  __typename?: 'PlayerResult';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export enum PortalDirectionEnum {
  NONE = 'NONE',
  PROMOTION_ARTIFACT = 'PROMOTION_ARTIFACT',
}

export type PortalIntro = {
  __typename?: 'PortalIntro';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  display?: Maybe<AdsPortalDisplay>;
  endTime?: Maybe<Scalars['DateTime']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isLogin?: Maybe<Scalars['Boolean']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  localRedirect?: Maybe<PortalDirectionEnum>;
  order?: Maybe<Scalars['Float']['output']>;
  prominence?: Maybe<Scalars['Boolean']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type PortalIntroPaginate = {
  __typename?: 'PortalIntroPaginate';
  items: Array<PortalIntro>;
  meta: BasePaginationMeta;
};

export type PortalIntroPaginateDto = {
  display?: InputMaybe<AdsPortalDisplay>;
  isValid?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  prominence?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ProductTypeEnum {
  GTEL = 'GTEL',
  MOBIPHONE = 'MOBIPHONE',
  VIETTEL = 'VIETTEL',
  VINAPHONE = 'VINAPHONE',
}

export type PromotionArtifact = {
  __typename?: 'PromotionArtifact';
  _id: Scalars['String']['output'];
  adsText?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endTime: Scalars['DateTime']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isHot: Scalars['Boolean']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  promotionStrategies: Array<PromotionStrategyArtifact>;
  rewardedTimeRange?: Maybe<Array<TimeRange>>;
  startTime: Scalars['DateTime']['output'];
  status: PromotionStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type PromotionArtifactPaginate = {
  __typename?: 'PromotionArtifactPaginate';
  items: Array<PromotionArtifact>;
  meta: BasePaginationMeta;
};

export type PromotionArtifactStrategyMessageMt = {
  __typename?: 'PromotionArtifactStrategyMessageMT';
  mtRegister?: Maybe<Scalars['String']['output']>;
  mtRenew?: Maybe<Scalars['String']['output']>;
};

export type PromotionArtifactStrategyMessageMtDto = {
  mtRegister?: InputMaybe<Scalars['String']['input']>;
  mtRenew?: InputMaybe<Scalars['String']['input']>;
  mtReward?: InputMaybe<Scalars['String']['input']>;
};

export type PromotionCampaign = {
  __typename?: 'PromotionCampaign';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  endTime: Scalars['DateTime']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isHotMobile?: Maybe<Scalars['Boolean']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  promotionStrategies: Array<PromotionStrategy>;
  rewardedTimeRange?: Maybe<Array<TimeRange>>;
  startTime: Scalars['DateTime']['output'];
  status: PromotionStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type PromotionCampaignPaginate = {
  __typename?: 'PromotionCampaignPaginate';
  items: Array<PromotionCampaign>;
  meta: BasePaginationMeta;
};

export type PromotionCode = {
  __typename?: 'PromotionCode';
  _id: Scalars['String']['output'];
  campaignId: Scalars['ID']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isHappenning: Scalars['Boolean']['output'];
  isdn: Scalars['String']['output'];
  packageCode: Scalars['String']['output'];
  promotionName: Scalars['String']['output'];
  rewardId?: Maybe<Scalars['ID']['output']>;
  rewardName: Scalars['String']['output'];
  rewardRank: Scalars['String']['output'];
  rewardedTimeRange?: Maybe<TimeRange>;
  status: PromotionCodeStatusEnum;
  type: PromotionCodeTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type PromotionCodePaginate = {
  __typename?: 'PromotionCodePaginate';
  items: Array<PromotionCode>;
  meta: BasePaginationMeta;
};

export enum PromotionCodeStatusEnum {
  EXPIRED = 'EXPIRED',
  NOT_WIN_PRIZE = 'NOT_WIN_PRIZE',
  PENDING = 'PENDING',
  WIN_PRIZE = 'WIN_PRIZE',
}

export enum PromotionCodeTypeEnum {
  SPECIAL = 'SPECIAL',
  TEMPORARILY = 'TEMPORARILY',
}

export type PromotionConfig = {
  __typename?: 'PromotionConfig';
  _id: Scalars['String']['output'];
  bonus?: Maybe<Scalars['Float']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  codeName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  packageCode?: Maybe<Scalars['String']['output']>;
  promotionId: Scalars['ID']['output'];
  promotionIntegrationName: Scalars['String']['output'];
  status: PromotionStatusEnum;
  type?: Maybe<PromotionConfigTypeEnum>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type PromotionConfigPaginate = {
  __typename?: 'PromotionConfigPaginate';
  items: Array<PromotionConfig>;
  meta: BasePaginationMeta;
};

export type PromotionConfigPaginateDto = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum PromotionConfigTypeEnum {
  DO_MISSION = 'DO_MISSION',
  PURCHASE_PACKAGE = 'PURCHASE_PACKAGE',
}

export type PromotionGift = {
  __typename?: 'PromotionGift';
  _id: Scalars['String']['output'];
  avatar: Scalars['String']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  giftsAnnouncedTotal?: Maybe<Scalars['Float']['output']>;
  giftsReceivedTotal?: Maybe<Scalars['Float']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  promotionId: Scalars['ID']['output'];
  promotionIntegrationName: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
  status: PromotionStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type PromotionGiftPaginate = {
  __typename?: 'PromotionGiftPaginate';
  items: Array<PromotionGift>;
  meta: BasePaginationMeta;
};

export type PromotionIntegration = {
  __typename?: 'PromotionIntegration';
  _id: Scalars['String']['output'];
  code?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  endTime: Scalars['DateTime']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isHotMobile?: Maybe<Scalars['Boolean']['output']>;
  localRedirect?: Maybe<DirectionEnum>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  redirectUrl?: Maybe<Scalars['String']['output']>;
  startTime: Scalars['DateTime']['output'];
  status: PromotionStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type PromotionIntegrationPaginate = {
  __typename?: 'PromotionIntegrationPaginate';
  items: Array<PromotionIntegration>;
  meta: BasePaginationMeta;
};

export type PromotionIntegrationPaginateDto = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  isHotMobile?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<PromotionStatusEnum>;
  type?: InputMaybe<PromotionConfigTypeEnum>;
};

export type PromotionReward = {
  __typename?: 'PromotionReward';
  _id: Scalars['String']['output'];
  campaignId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  expiredTime?: Maybe<Scalars['DateTime']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  mtReward?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  promotionName: Scalars['String']['output'];
  rewardNum: Scalars['Int']['output'];
  rewardedTimeRange?: Maybe<TimeRange>;
  type: PromotionRewardTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type PromotionRewardPaginate = {
  __typename?: 'PromotionRewardPaginate';
  items: Array<PromotionReward>;
  meta: BasePaginationMeta;
};

export enum PromotionRewardTypeEnum {
  CONSOLATION = 'CONSOLATION',
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  SPECIAL = 'SPECIAL',
  THIRD = 'THIRD',
}

export enum PromotionStatusEnum {
  FINISHED = 'FINISHED',
  HAPPENING = 'HAPPENING',
  IN_COMING = 'IN_COMING',
}

export type PromotionStrategy = {
  __typename?: 'PromotionStrategy';
  _id: Scalars['String']['output'];
  campaignId: Scalars['ID']['output'];
  condition?: Maybe<PromotionStrategyCondition>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  messageMT?: Maybe<PromotionStrategyMessageMt>;
  packageCode: Scalars['String']['output'];
  reward?: Maybe<PromotionStrategyReward>;
  smsCode?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export type PromotionStrategyArtifact = {
  __typename?: 'PromotionStrategyArtifact';
  _id: Scalars['String']['output'];
  campaignId: Scalars['ID']['output'];
  condition?: Maybe<PromotionStrategyCondition>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  messageMT?: Maybe<PromotionArtifactStrategyMessageMt>;
  packageCode: Scalars['String']['output'];
  reward?: Maybe<Array<PromotionStrategyArtifactReward>>;
  specialCode?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type PromotionStrategyArtifactReward = {
  __typename?: 'PromotionStrategyArtifactReward';
  code?: Maybe<Scalars['Int']['output']>;
  rewardedTimeRange?: Maybe<TimeRange>;
};

export type PromotionStrategyArtifactRewardDto = {
  code?: InputMaybe<Scalars['Int']['input']>;
  rewardedTimeRange?: InputMaybe<TimeRangeDto>;
};

export type PromotionStrategyCondition = {
  __typename?: 'PromotionStrategyCondition';
  isContinuous: Scalars['Boolean']['output'];
  isGetFirst: Scalars['Boolean']['output'];
  repeatedRegister: Scalars['Int']['output'];
};

export type PromotionStrategyConditionDto = {
  isContinuous: Scalars['Boolean']['input'];
  isGetFirst: Scalars['Boolean']['input'];
  repeatedRegister: Scalars['Int']['input'];
};

export type PromotionStrategyMessageMt = {
  __typename?: 'PromotionStrategyMessageMT';
  mtRegisterRenew?: Maybe<Scalars['String']['output']>;
  mtReward?: Maybe<Scalars['String']['output']>;
};

export type PromotionStrategyMessageMtDto = {
  mtRegisterRenew?: InputMaybe<Scalars['String']['input']>;
  mtReward?: InputMaybe<Scalars['String']['input']>;
};

export type PromotionStrategyReward = {
  __typename?: 'PromotionStrategyReward';
  moneyValue?: Maybe<Scalars['Int']['output']>;
  rewardTimes?: Maybe<Scalars['Int']['output']>;
};

export type PromotionStrategyRewardDto = {
  moneyValue?: InputMaybe<Scalars['Int']['input']>;
  rewardTimes?: InputMaybe<Scalars['Int']['input']>;
};

export type PromotionUnion = {
  __typename?: 'PromotionUnion';
  _id: Scalars['String']['output'];
  code?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endTime: Scalars['DateTime']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isHot?: Maybe<Scalars['Boolean']['output']>;
  isHotMobile?: Maybe<Scalars['Boolean']['output']>;
  localRedirect?: Maybe<DirectionEnum>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  redirectUrl?: Maybe<Scalars['String']['output']>;
  rewardedTimeRange?: Maybe<Array<TimeRange>>;
  startTime: Scalars['DateTime']['output'];
  status: PromotionStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type PromotionUnionPaginate = {
  __typename?: 'PromotionUnionPaginate';
  items: Array<PromotionUnion>;
  meta: BasePaginationMeta;
};

export type PromotionUnionPaginateDto = {
  adsText?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  isHot?: InputMaybe<Scalars['Boolean']['input']>;
  isHotMobile?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  packageCode?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<PromotionStatusEnum>;
  type?: InputMaybe<PromotionConfigTypeEnum>;
};

export type PromotionUserReceivedGiftPaginateDto = {
  /** 2024-09-10 */
  endTime?: InputMaybe<Scalars['String']['input']>;
  giftCode?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  /** 2024-09-10 */
  startTime?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<StateUserReceivedGiftEnum>;
};

export type PromotionUserReceivedGifts = {
  __typename?: 'PromotionUserReceivedGifts';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  expireTime: Scalars['DateTime']['output'];
  giftCode?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  promotionCode: Scalars['String']['output'];
  quantity?: Maybe<Scalars['Float']['output']>;
  requestId?: Maybe<Scalars['String']['output']>;
  state?: Maybe<StateUserReceivedGiftEnum>;
  time: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
  userRefId?: Maybe<Scalars['String']['output']>;
};

export type PromotionUserReceivedGiftsPaginate = {
  __typename?: 'PromotionUserReceivedGiftsPaginate';
  items: Array<PromotionUserReceivedGifts>;
  meta: BasePaginationMeta;
};

export type Query = {
  __typename?: 'Query';
  ads: Ads;
  adsList: AdsPaginate;
  adsReward: AdsReward;
  adsRewards: AdsRewardPaginate;
  allContentProviderByAdsText?: Maybe<ContentProviderG1>;
  allContentProviders: ContentProviderG1Paginate;
  banner: Banner;
  banners: BannerConnection;
  bigEvent: EventTouramentConnection;
  bracketInTournamentOfflines: Array<BracketInTour>;
  categories: CategoryConnection;
  contact: Contact;
  contacts: ContactPaginate;
  contentProvider: ContentProvider;
  contentProviderByAdsText: ContentProvider;
  contentProviders: ContentProviderPaginate;
  conversionRate: ConversionRate;
  eventTourament: EventTourament;
  eventTouraments: EventTouramentConnection;
  favoriteInfo: Array<FavoriteInfo>;
  favorites: GameConnection;
  featuredGame: GameConnection;
  feedback: Feedback;
  feedbackByGame: FeedbackConnection;
  feedbacks: FeedbackConnection;
  footer: Footer;
  footers: FooterPaginate;
  g1ContentProvider: ContentProviderG1;
  g1ContentProviders: ContentProviderG1Paginate;
  g1FavoriteInfo: Array<FavoriteInfo>;
  g1FeaturedGame: GameG1Paginate;
  g1Game: GameG1;
  g1GameByCategory: GameG1Paginate;
  g1Games: GameG1Paginate;
  g1GamesByCpId: GameG1Paginate;
  g1LikeInfo: Array<LikesInfo>;
  g1SearchGame: GameG1Paginate;
  game: Game;
  gameByCategory: GameConnection;
  gamePortal: Game;
  games: GameConnection;
  gamesByCpId: GameConnection;
  gamesPortal: GameConnection;
  getCategoryById: CategoryGraphQl;
  getCommentById: CommentGraphQl;
  getCommentByRelationId: Array<CommentGraphQl>;
  getComments: CommentPaginate;
  getDataBracket: Array<BracketInTourInterface>;
  getDoubleBracketData: DoubleBracketData;
  getLoyalty: Array<Loyalty>;
  getLoyaltyById: Loyalty;
  getPayRewards: PayRewardsPaginate;
  getPinVideo: Video;
  getTagById: TagGraphQl;
  getTags: Array<TagGraphQl>;
  getTournamentOfflineNews: ResponseGetTournamentOfflineNews;
  getTournamentOfflineNewsById: TournamentOfflineNewsGraphQl;
  likeInfo: Array<LikesInfo>;
  listConversionRates: ConversionRateConnection;
  listNews: NewsPaginate;
  listNewsPortal: NewsPaginate;
  logById: Log;
  logs: LogPaginate;
  mission: Mission;
  missionV2: MissionV2;
  missions: MissionConnection;
  missionsV2: MissionV2Paginate;
  mobileIntro: MobileIntro;
  mobileIntroes: MobileIntroPaginate;
  news: News;
  newsPortal: News;
  playersInEvent: PlayerInEventTournamentPaginate;
  playersInRoom: PlayerInRoomConnection;
  portalIntro: PortalIntro;
  portalIntroes: PortalIntroPaginate;
  promotionArtifact: PromotionArtifact;
  promotionArtifactByAdsText: PromotionArtifact;
  promotionArtifactCode: PromotionCode;
  promotionArtifactCodes: PromotionCodePaginate;
  promotionArtifactReward: PromotionReward;
  promotionArtifactRewards: PromotionRewardPaginate;
  promotionArtifacts: PromotionArtifactPaginate;
  promotionCampaign: PromotionCampaign;
  promotionCampaigns: PromotionCampaignPaginate;
  promotionConfig: PromotionConfig;
  promotionConfigs: PromotionConfigPaginate;
  promotionGift: PromotionGift;
  promotionIntegration: PromotionIntegration;
  promotionIntegrations: PromotionIntegrationPaginate;
  promotionUnions: PromotionUnionPaginate;
  promotionUserReceivedGifts: PromotionUserReceivedGiftsPaginate;
  promotionsGifts: PromotionGiftPaginate;
  regionCompanies: RegionCompanyPaginate;
  regionCompany: RegionCompany;
  room: Room;
  rooms: RoomConnection;
  search: SearchGraphQl;
  searchGame: SearchGameConnection;
  slider: Slider;
  sliders: SliderPaginate;
  starRate: ConversionRateInterface;
  tableRankings: TableRankingConnection;
  taskForUser: UserTaskConnection;
  teamInTourOffline: TeamInTourOffline;
  teamInTourOfflines: TeamInTourOfflinePagination;
  tokenRate: ConversionRateInterface;
  totalFavorite: TotalFavorite;
  tourOffline: TourOffline;
  tourOfflineByUser: TourOfflineConnection;
  tourOfflines: TourOfflineConnection;
  video: Video;
  videoPortal: Video;
  videoTournamentOffline: VideoTournamentOfflineExtend;
  videoTournamentOfflines: VideoTournamentOfflinePagination;
  videos: VideoPaginate;
  videosPortal: VideoPaginate;
};

export type QueryAdsArgs = {
  _id: Scalars['ID']['input'];
};

export type QueryAdsListArgs = {
  query: AdsPaginateDto;
};

export type QueryAdsRewardArgs = {
  _id: Scalars['ID']['input'];
};

export type QueryAdsRewardsArgs = {
  query: AdsRewardPaginateDto;
};

export type QueryAllContentProviderByAdsTextArgs = {
  adsText: Scalars['String']['input'];
};

export type QueryAllContentProvidersArgs = {
  query: QueryContentProviderG1Dto;
};

export type QueryBannerArgs = {
  id: Scalars['String']['input'];
};

export type QueryBigEventArgs = {
  query: EventTouramentPaginateDto;
};

export type QueryBracketInTournamentOfflinesArgs = {
  tournamentId: Scalars['ID']['input'];
};

export type QueryCategoriesArgs = {
  query: CategoryPaginateDto;
};

export type QueryContactArgs = {
  _id: Scalars['ID']['input'];
};

export type QueryContactsArgs = {
  query: ContactPaginateDto;
};

export type QueryContentProviderArgs = {
  id: Scalars['String']['input'];
};

export type QueryContentProviderByAdsTextArgs = {
  adsText: Scalars['String']['input'];
};

export type QueryContentProvidersArgs = {
  query: QueryContentProviderDto;
};

export type QueryConversionRateArgs = {
  id: Scalars['String']['input'];
};

export type QueryEventTouramentArgs = {
  id: Scalars['String']['input'];
};

export type QueryEventTouramentsArgs = {
  query: EventTouramentPaginateDto;
};

export type QueryFavoriteInfoArgs = {
  game: Array<Scalars['String']['input']>;
};

export type QueryFavoritesArgs = {
  query: GamePaginateDto;
};

export type QueryFeaturedGameArgs = {
  query: GamePaginateDto;
};

export type QueryFeedbackArgs = {
  id: Scalars['String']['input'];
};

export type QueryFeedbackByGameArgs = {
  gameId: Scalars['String']['input'];
  input: FeedbackPaginateDto;
};

export type QueryFeedbacksArgs = {
  query: FeedbackPaginateDto;
};

export type QueryFooterArgs = {
  _id: Scalars['ID']['input'];
};

export type QueryFootersArgs = {
  query: FooterPaginateDto;
};

export type QueryG1ContentProviderArgs = {
  id: Scalars['String']['input'];
};

export type QueryG1ContentProvidersArgs = {
  query: QueryContentProviderG1Dto;
};

export type QueryG1FavoriteInfoArgs = {
  game: Array<Scalars['String']['input']>;
};

export type QueryG1FeaturedGameArgs = {
  query: GameG1PaginateDto;
};

export type QueryG1GameArgs = {
  id: Scalars['String']['input'];
};

export type QueryG1GameByCategoryArgs = {
  categoryIds: Array<Scalars['String']['input']>;
  input: GameG1PaginateDto;
};

export type QueryG1GamesArgs = {
  query: GameG1PaginateDto;
};

export type QueryG1GamesByCpIdArgs = {
  query: GameG1PaginateDto;
};

export type QueryG1LikeInfoArgs = {
  game: Array<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryG1SearchGameArgs = {
  input: GameG1PaginateDto;
};

export type QueryGameArgs = {
  id: Scalars['String']['input'];
};

export type QueryGameByCategoryArgs = {
  categoryIds: Array<Scalars['String']['input']>;
  input: GamePaginateDto;
};

export type QueryGamePortalArgs = {
  id: Scalars['String']['input'];
};

export type QueryGamesArgs = {
  query: GamePaginateDto;
};

export type QueryGamesByCpIdArgs = {
  query: GamePaginateDto;
};

export type QueryGamesPortalArgs = {
  query: GamePaginateDto;
};

export type QueryGetCategoryByIdArgs = {
  _id: Scalars['String']['input'];
};

export type QueryGetCommentByIdArgs = {
  _id: Scalars['String']['input'];
};

export type QueryGetCommentByRelationIdArgs = {
  relationId: Scalars['String']['input'];
};

export type QueryGetCommentsArgs = {
  query: CommentPaginateDto;
};

export type QueryGetDataBracketArgs = {
  tourOffline: Scalars['String']['input'];
};

export type QueryGetDoubleBracketDataArgs = {
  tourOffline: Scalars['String']['input'];
};

export type QueryGetLoyaltyByIdArgs = {
  _id: Scalars['String']['input'];
};

export type QueryGetPayRewardsArgs = {
  query: PayRewardsPaginateDto;
};

export type QueryGetTagByIdArgs = {
  _id: Scalars['String']['input'];
};

export type QueryGetTagsArgs = {
  type: Scalars['Int']['input'];
};

export type QueryGetTournamentOfflineNewsArgs = {
  query: QueryTournamentOfflineNewsDto;
};

export type QueryGetTournamentOfflineNewsByIdArgs = {
  _id: Scalars['String']['input'];
};

export type QueryLikeInfoArgs = {
  game: Array<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryListConversionRatesArgs = {
  query: ConversionRatePaginateDto;
};

export type QueryListNewsArgs = {
  query: NewsPaginateDto;
};

export type QueryListNewsPortalArgs = {
  query: NewsPaginateDto;
};

export type QueryLogByIdArgs = {
  _id: Scalars['ID']['input'];
};

export type QueryLogsArgs = {
  query: LogPaginateDto;
};

export type QueryMissionArgs = {
  id: Scalars['String']['input'];
};

export type QueryMissionV2Args = {
  id: Scalars['String']['input'];
};

export type QueryMissionsV2Args = {
  query: MissionV2PaginateDto;
};

export type QueryMobileIntroArgs = {
  _id: Scalars['ID']['input'];
};

export type QueryMobileIntroesArgs = {
  query: MobileIntroPaginateDto;
};

export type QueryNewsArgs = {
  _id: Scalars['String']['input'];
};

export type QueryNewsPortalArgs = {
  _id: Scalars['String']['input'];
};

export type QueryPlayersInEventArgs = {
  query: PlayerInEventPaginateDto;
};

export type QueryPlayersInRoomArgs = {
  query: PlayerInRoomPaginateDto;
  roomId: Scalars['String']['input'];
};

export type QueryPortalIntroArgs = {
  _id: Scalars['ID']['input'];
};

export type QueryPortalIntroesArgs = {
  query: PortalIntroPaginateDto;
};

export type QueryPromotionArtifactArgs = {
  id: Scalars['String']['input'];
};

export type QueryPromotionArtifactByAdsTextArgs = {
  adsText: Scalars['String']['input'];
};

export type QueryPromotionArtifactCodeArgs = {
  id: Scalars['String']['input'];
};

export type QueryPromotionArtifactCodesArgs = {
  query: QueryPromotionCodeDto;
};

export type QueryPromotionArtifactRewardArgs = {
  id: Scalars['String']['input'];
};

export type QueryPromotionArtifactRewardsArgs = {
  query: QueryPromotionRewardDto;
};

export type QueryPromotionArtifactsArgs = {
  query: QueryPromotionArtifactDto;
};

export type QueryPromotionCampaignArgs = {
  id: Scalars['String']['input'];
};

export type QueryPromotionCampaignsArgs = {
  query: QueryPromotionCampaignDto;
};

export type QueryPromotionConfigArgs = {
  id: Scalars['String']['input'];
};

export type QueryPromotionConfigsArgs = {
  query: PromotionConfigPaginateDto;
};

export type QueryPromotionGiftArgs = {
  id: Scalars['String']['input'];
};

export type QueryPromotionIntegrationArgs = {
  id: Scalars['String']['input'];
};

export type QueryPromotionIntegrationsArgs = {
  query: PromotionIntegrationPaginateDto;
};

export type QueryPromotionUnionsArgs = {
  query: PromotionUnionPaginateDto;
};

export type QueryPromotionUserReceivedGiftsArgs = {
  query: PromotionUserReceivedGiftPaginateDto;
};

export type QueryPromotionsGiftsArgs = {
  query: PromotionConfigPaginateDto;
};

export type QueryRegionCompaniesArgs = {
  query: RegionCompanyPaginateDto;
};

export type QueryRegionCompanyArgs = {
  id: Scalars['String']['input'];
};

export type QueryRoomArgs = {
  id: Scalars['String']['input'];
};

export type QueryRoomsArgs = {
  query: RoomPaginateDto;
};

export type QuerySearchArgs = {
  query: SearchDto;
};

export type QuerySearchGameArgs = {
  input: GamePaginateDto;
};

export type QuerySliderArgs = {
  _id: Scalars['ID']['input'];
};

export type QuerySlidersArgs = {
  query: SliderPaginateDto;
};

export type QueryTableRankingsArgs = {
  query: TableRankingPaginateDto;
};

export type QueryTaskForUserArgs = {
  input: UserTaskPaginateDto;
};

export type QueryTeamInTourOfflineArgs = {
  id: Scalars['String']['input'];
};

export type QueryTeamInTourOfflinesArgs = {
  query: TeamInTourOfflinePaginateDto;
};

export type QueryTotalFavoriteArgs = {
  user?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryTourOfflineArgs = {
  id: Scalars['String']['input'];
};

export type QueryTourOfflineByUserArgs = {
  query: TourOfflinePaginateDto;
};

export type QueryTourOfflinesArgs = {
  query: TourOfflinePaginateDto;
};

export type QueryVideoArgs = {
  _id: Scalars['String']['input'];
};

export type QueryVideoPortalArgs = {
  _id: Scalars['String']['input'];
};

export type QueryVideoTournamentOfflineArgs = {
  _id: Scalars['ID']['input'];
};

export type QueryVideoTournamentOfflinesArgs = {
  query: VideoTournamentOfflinePaginateDto;
};

export type QueryVideosArgs = {
  query: VideoPaginateDto;
};

export type QueryVideosPortalArgs = {
  query: VideoPaginateDto;
};

export type QueryContentProviderDto = {
  isActivate?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryContentProviderG1Dto = {
  isActivate?: InputMaybe<Scalars['Boolean']['input']>;
  isNotG1Only?: InputMaybe<Scalars['Boolean']['input']>;
  isValidGame?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<ContentProviderTypeEnum>;
};

export type QueryPromotionArtifactDto = {
  adsText?: InputMaybe<Scalars['String']['input']>;
  isHot?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  packageCode?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<PromotionStatusEnum>;
};

export type QueryPromotionCampaignDto = {
  isHotMobile?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  packageCode?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<PromotionStatusEnum>;
};

export type QueryPromotionCodeDto = {
  campainId?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  isdn?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PromotionCodeTypeEnum>;
};

export type QueryPromotionRewardDto = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  promotionId?: InputMaybe<Scalars['String']['input']>;
  promotionName?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<PromotionRewardTypeEnum>;
};

export type QueryTournamentOfflineNewsDto = {
  endTime?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  startTime?: InputMaybe<Scalars['Int']['input']>;
  tournamentOffline?: InputMaybe<Scalars['String']['input']>;
};

export type Ranking = {
  Rfour?: InputMaybe<Scalars['Float']['input']>;
  Rone: Scalars['Float']['input'];
  Rthree: Scalars['Float']['input'];
  Rtwo: Scalars['Float']['input'];
};

export type RankingModel = {
  __typename?: 'RankingModel';
  Rfour?: Maybe<Scalars['Float']['output']>;
  Rone: Scalars['Float']['output'];
  Rthree: Scalars['Float']['output'];
  Rtwo: Scalars['Float']['output'];
};

export type Rate = {
  __typename?: 'Rate';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Float']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  ratio: Scalars['Float']['output'];
  type: RateTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type RateDto = {
  createdBy?: InputMaybe<Scalars['Float']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  ratio: Scalars['Float']['input'];
  type: RateTypeEnum;
};

export enum RateTypeEnum {
  STAR = 'STAR',
  TOKEN = 'TOKEN',
}

export type RegionCompany = {
  __typename?: 'RegionCompany';
  _id: Scalars['String']['output'];
  benefit?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  subCommands: Array<SubCommand>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type RegionCompanyPaginate = {
  __typename?: 'RegionCompanyPaginate';
  items: Array<RegionCompany>;
  meta: BasePaginationMeta;
};

export type RegionCompanyPaginateDto = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  packageCode?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  subCommand?: InputMaybe<Scalars['String']['input']>;
};

export type Remove = {
  __typename?: 'Remove';
  _id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
};

export type ResponseGetTournamentOfflineNews = {
  __typename?: 'ResponseGetTournamentOfflineNews';
  data: Array<TournamentOfflineNewsGraphQl>;
  paging: Paging;
};

export type Reward = {
  __typename?: 'Reward';
  name?: Maybe<Scalars['String']['output']>;
  ranking: RankingModel;
  type: EventTouramentRewardEnum;
};

export type RewardDto = {
  name?: InputMaybe<Scalars['String']['input']>;
  ranking: Ranking;
  type: EventTouramentRewardEnum;
};

export type Room = {
  __typename?: 'Room';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Float']['output'];
  creatorInfo?: Maybe<CreatorInfo>;
  endDate: Scalars['DateTime']['output'];
  game?: Maybe<Scalars['String']['output']>;
  gameInfo?: Maybe<Game>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  roomId?: Maybe<Scalars['String']['output']>;
  roomPassword: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  status: RoomStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type RoomConnection = {
  __typename?: 'RoomConnection';
  items?: Maybe<Array<Room>>;
  meta: BasePaginationMeta;
};

export type RoomPaginateDto = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export enum RoomStatusEnum {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
}

export type SearchDto = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchGameConnection = {
  __typename?: 'SearchGameConnection';
  categories?: Maybe<CategoryConnection>;
  games?: Maybe<GameConnection>;
};

export type SearchGraphQl = {
  __typename?: 'SearchGraphQL';
  game: GameConnection;
  news: NewsResultGraphQl;
  video: VideoResultGraphQl;
};

export type Slider = {
  __typename?: 'Slider';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  desc?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isMobileOnly?: Maybe<Scalars['Boolean']['output']>;
  localRedirect?: Maybe<DirectionEnum>;
  order?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<TypeSliderEnum>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  url: Scalars['String']['output'];
  urlThumbnail?: Maybe<Scalars['String']['output']>;
};

export type SliderPaginate = {
  __typename?: 'SliderPaginate';
  items: Array<Slider>;
  meta: BasePaginationMeta;
};

export type SliderPaginateDto = {
  isMobile?: InputMaybe<Scalars['Boolean']['input']>;
  isPortal?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<TypeSliderEnum>;
};

export enum StateUserReceivedGiftEnum {
  PUSHED_NOTI = 'PUSHED_NOTI',
  REAL_RECEIVED = 'REAL_RECEIVED',
  RECEIVED_IN_GAME = 'RECEIVED_IN_GAME',
}

export type SubCommand = {
  __typename?: 'SubCommand';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  packageCode?: Maybe<Scalars['String']['output']>;
  packageId?: Maybe<Scalars['Float']['output']>;
  subCommand?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

export type SyncDataGame = {
  __typename?: 'SyncDataGame';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Float']['output'];
  eventId: Scalars['String']['output'];
  gameId: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  level: Scalars['Float']['output'];
  roomId: Scalars['String']['output'];
  score: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  userId: Scalars['Float']['output'];
};

export type SyncDataGameInterface = {
  __typename?: 'SyncDataGameInterface';
  eventId?: Maybe<Scalars['String']['output']>;
  gameId?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['Float']['output']>;
  roomId?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

export type TableRanking = {
  __typename?: 'TableRanking';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  score: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  user: Scalars['Float']['output'];
};

export type TableRankingConnection = {
  __typename?: 'TableRankingConnection';
  items?: Maybe<Array<TableRanking>>;
  meta: BasePaginationMeta;
};

export type TableRankingPaginateDto = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TagGraphQl = {
  __typename?: 'TagGraphQL';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['Int']['output'];
  createdBy?: Maybe<Scalars['Int']['output']>;
  desc?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: Scalars['Int']['output'];
};

export type Task = {
  __typename?: 'Task';
  completedCount: Scalars['Float']['output'];
  isCollect: Scalars['Boolean']['output'];
  isCompleted: Scalars['Boolean']['output'];
  missionInfo?: Maybe<Mission>;
  taskId: Scalars['String']['output'];
};

export type TaskDto = {
  completedCount?: Scalars['Float']['input'];
  isCollect?: Scalars['Boolean']['input'];
  isCompleted?: Scalars['Boolean']['input'];
  taskId: Scalars['String']['input'];
};

export type Team = {
  __typename?: 'Team';
  _id: Scalars['String']['output'];
  avatar: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  member?: Maybe<Array<MemberModel>>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type TeamInBracketDto = {
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isWinner?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  resultText?: InputMaybe<Scalars['String']['input']>;
  results?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<TeamInBracketStatusEnum>;
};

export type TeamInBracketInterface = {
  __typename?: 'TeamInBracketInterface';
  id?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isWinner?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  resultText?: Maybe<Scalars['String']['output']>;
  results?: Maybe<Array<Scalars['String']['output']>>;
  status?: Maybe<TeamInBracketStatusEnum>;
};

export type TeamInBracketModel = {
  __typename?: 'TeamInBracketModel';
  id?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isWinner?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  resultText?: Maybe<Scalars['String']['output']>;
  results?: Maybe<Array<Scalars['String']['output']>>;
  status?: Maybe<TeamInBracketStatusEnum>;
};

export enum TeamInBracketStatusEnum {
  NOTJOIN = 'NOTJOIN',
  PLAYED = 'PLAYED',
}

export type TeamInTourOffline = {
  __typename?: 'TeamInTourOffline';
  _id: Scalars['String']['output'];
  avatar: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Int']['output']>;
  inMatch?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isFinished?: Maybe<Scalars['Boolean']['output']>;
  members?: Maybe<Array<MemberTeamInTournamentOffline>>;
  name: Scalars['String']['output'];
  status: TeamInTourStatusEnum;
  tourOffline: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type TeamInTourOfflinePaginateDto = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  tournamentId: Scalars['ID']['input'];
};

export type TeamInTourOfflinePagination = {
  __typename?: 'TeamInTourOfflinePagination';
  items: Array<TeamInTourOffline>;
  meta: BasePaginationMeta;
};

export enum TeamInTourStatusEnum {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REFUSE = 'REFUSE',
}

export type TimeRange = {
  __typename?: 'TimeRange';
  endTime: Scalars['DateTime']['output'];
  startTime: Scalars['DateTime']['output'];
};

export type TimeRangeDto = {
  endTime: Scalars['DateTime']['input'];
  startTime: Scalars['DateTime']['input'];
};

export type TotalFavorite = {
  __typename?: 'TotalFavorite';
  total?: Maybe<Scalars['Float']['output']>;
};

export type TourOffline = {
  __typename?: 'TourOffline';
  _id: Scalars['String']['output'];
  competitionFormat: GameConfigurationTourOffline;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  game?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isFinished?: Maybe<Scalars['Boolean']['output']>;
  linkRegisterJoin?: Maybe<Scalars['String']['output']>;
  memberInTeams: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  numberTeams?: Maybe<Scalars['Float']['output']>;
  organizer?: Maybe<Scalars['String']['output']>;
  reward?: Maybe<Scalars['Float']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status: TourOfflineStatusEnum;
  thumbnail?: Maybe<Scalars['String']['output']>;
  typeTournament: TypeTournament;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type TourOfflineConnection = {
  __typename?: 'TourOfflineConnection';
  items: Array<TourOffline>;
  meta: BasePaginationMeta;
};

export enum TourOfflineFormulaEnum {
  BRANCHING = 'BRANCHING',
  KNOCKOUT = 'KNOCKOUT',
  SCORINGCIRCLE = 'SCORINGCIRCLE',
}

export enum TourOfflineMethodWinLoseEnum {
  ONEORTWO = 'ONEORTWO',
  ONLYONE = 'ONLYONE',
}

export type TourOfflinePaginateDto = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  isParticipant?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Float']['input']>;
};

export enum TourOfflineRankedEnum {
  NUMBEROFWIN = 'NUMBEROFWIN',
  SCORES = 'SCORES',
}

export enum TourOfflineStatusEnum {
  FINISHED = 'FINISHED',
  HAPPENING = 'HAPPENING',
  UPCOMING = 'UPCOMING',
}

export enum TourOfflineTurnEnum {
  ONE = 'ONE',
  TWO = 'TWO',
}

export type TournamentOfflineNewsGraphQl = {
  __typename?: 'TournamentOfflineNewsGraphQL';
  _id: Scalars['ID']['output'];
  body: Scalars['String']['output'];
  createdAt: Scalars['Int']['output'];
  createdBy?: Maybe<Scalars['Int']['output']>;
  status: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  tournamentOffline: Scalars['String']['output'];
};

export enum TypeFeedbackEnum {
  INCOGNITO = 'INCOGNITO',
  PUBLIC = 'PUBLIC',
}

export enum TypeSliderEnum {
  GAME = 'Game',
  HOME = 'Home',
  NEWS = 'News',
}

export enum TypeTournament {
  SELFREGISTER = 'SELFREGISTER',
  SELFSUPPLY = 'SELFSUPPLY',
}

export enum TypeVideoTournamentOfflineEnum {
  LIVESTREAM = 'LIVESTREAM',
  PLAYBACK = 'PLAYBACK',
}

export type UpdateAdsDto = {
  _id: Scalars['ID']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAdsRewardDto = {
  _id: Scalars['ID']['input'];
  energyPerView?: Scalars['Int']['input'];
  viewLimit?: Scalars['Int']['input'];
};

export type UpdateBannerDto = {
  _id: Scalars['String']['input'];
  createdBy?: InputMaybe<Scalars['Float']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  updatedBy?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBracketInTourDto = {
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['String']['input']>;
  matchIndex?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextLooserMatchId?: InputMaybe<Scalars['String']['input']>;
  nextMatchId?: InputMaybe<Scalars['String']['input']>;
  participants?: InputMaybe<Array<TeamInBracketDto>>;
  position?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<BracketInTourStateEnum>;
  tourOfflineId?: InputMaybe<Scalars['String']['input']>;
  tournamentRoundText?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCategoryDto = {
  _id: Scalars['ID']['input'];
  author?: InputMaybe<AuthorDto>;
  desc?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  pathThumbnail?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
  urlThumbnail?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCommentDto = {
  _id: Scalars['String']['input'];
  content: Scalars['String']['input'];
};

export type UpdateContentProviderDto = {
  _id: Scalars['ID']['input'];
  address?: InputMaybe<Scalars['String']['input']>;
  adsText?: InputMaybe<Scalars['String']['input']>;
  apiGateway?: InputMaybe<Scalars['String']['input']>;
  appleStoreUrl?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  contractCode?: InputMaybe<Scalars['String']['input']>;
  contractEndDate?: InputMaybe<Scalars['String']['input']>;
  contractStartDate?: InputMaybe<Scalars['String']['input']>;
  customerSupport?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['String']['input']>;
  document?: InputMaybe<Scalars['String']['input']>;
  ftp?: InputMaybe<FtpInputDto>;
  googlePlayUrl?: InputMaybe<Scalars['String']['input']>;
  guideDesc?: InputMaybe<Scalars['String']['input']>;
  isActivate?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  manager?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  presentor?: InputMaybe<Scalars['String']['input']>;
  presentorEmail?: InputMaybe<Scalars['String']['input']>;
  qrImage?: InputMaybe<Scalars['String']['input']>;
  sharedProfit?: InputMaybe<Scalars['Int']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContentProviderG1Dto = {
  _id: Scalars['ID']['input'];
  address?: InputMaybe<Scalars['String']['input']>;
  adsText?: InputMaybe<Scalars['String']['input']>;
  apiGateway?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  contractCode?: InputMaybe<Scalars['String']['input']>;
  contractEndDate?: InputMaybe<Scalars['String']['input']>;
  contractStartDate?: InputMaybe<Scalars['String']['input']>;
  customerSupport?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['String']['input']>;
  document?: InputMaybe<Scalars['String']['input']>;
  ftp?: InputMaybe<FtpInputDto>;
  isActivate?: InputMaybe<Scalars['Boolean']['input']>;
  isG1Only?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  manager?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  presentor?: InputMaybe<Scalars['String']['input']>;
  presentorEmail?: InputMaybe<Scalars['String']['input']>;
  sharedProfit?: InputMaybe<Scalars['Int']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateConversionRateDto = {
  _id: Scalars['String']['input'];
  conversionName?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['Float']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  token?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<ConversionRateTypeEnum>;
};

export type UpdateEventTouramentDto = {
  _id: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  game?: InputMaybe<Array<Scalars['String']['input']>>;
  isDeleted?: Scalars['Boolean']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  prominence?: InputMaybe<Scalars['Boolean']['input']>;
  reward?: InputMaybe<Array<RewardDto>>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<EventTouramentStatusEnum>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EventTouramentTypeEnum>;
};

export type UpdateFavoriteGameDto = {
  game: Scalars['String']['input'];
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  user?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateFeedbackDto = {
  _id: Scalars['String']['input'];
  comment: Scalars['String']['input'];
};

export type UpdateFooterDto = {
  _id: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  type: FooterTypeEnum;
};

export type UpdateGameDto = {
  _id: Scalars['String']['input'];
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  contentProviderId?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['Float']['input']>;
  displayOn?: InputMaybe<DisplayOnEnum>;
  equipment?: InputMaybe<Array<Scalars['String']['input']>>;
  file?: InputMaybe<Scalars['String']['input']>;
  gallery?: InputMaybe<Array<Scalars['String']['input']>>;
  imageHorizontal?: InputMaybe<Scalars['String']['input']>;
  instruct?: InputMaybe<Scalars['String']['input']>;
  introduce?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isRotated?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  mobileType?: InputMaybe<GameMobileTypeEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
  prominence?: InputMaybe<Scalars['Boolean']['input']>;
  rateStar?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<GameStatusEnum>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  urlZipGame?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGameG1Dto = {
  _id: Scalars['String']['input'];
  appleStoreUrl?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  contentProviderId?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  gallery?: InputMaybe<Array<Scalars['String']['input']>>;
  googlePlayUrl?: InputMaybe<Scalars['String']['input']>;
  guideDesc?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  prominence?: InputMaybe<Scalars['Boolean']['input']>;
  qrImage?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<GameStatusEnum>;
};

export type UpdateLikeDto = {
  game: Scalars['String']['input'];
  isLike?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateLoyaltyDto = {
  _id: Scalars['String']['input'];
  desc: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateMissionDto = {
  _id: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performs?: InputMaybe<Scalars['Float']['input']>;
  reward?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<MissionStatusEnum>;
};

export type UpdateMissionV2Dto = {
  _id: Scalars['ID']['input'];
  completedQuantity?: InputMaybe<Array<MissionCompletedQuantityDto>>;
  cycle?: InputMaybe<CycleTypeEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  isAddBalanceNextDay?: InputMaybe<Scalars['Boolean']['input']>;
  isConsecutiveCompletedDay?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  performs?: InputMaybe<Scalars['Int']['input']>;
  rewardEnergy?: InputMaybe<Scalars['Int']['input']>;
  rewardGpoint?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<MissionV2StatusEnum>;
  type?: InputMaybe<MissionTypeEnum>;
};

export type UpdateMobileIntroDto = {
  _id: Scalars['ID']['input'];
  display?: InputMaybe<AdsMobileDisplay>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isProminent?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  localRedirect?: InputMaybe<DirectionEnum>;
  order?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateNewsDto = {
  _id: Scalars['String']['input'];
  author?: InputMaybe<AuthorDto>;
  body?: InputMaybe<Scalars['String']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  coverUrl?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['String']['input']>;
  isDislike?: InputMaybe<Scalars['Boolean']['input']>;
  isHot?: InputMaybe<Scalars['Boolean']['input']>;
  isLike?: InputMaybe<Scalars['Boolean']['input']>;
  isShared?: InputMaybe<Scalars['Boolean']['input']>;
  publishTime?: InputMaybe<Scalars['DateTime']['input']>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePortalIntroDto = {
  _id: Scalars['ID']['input'];
  display?: InputMaybe<AdsPortalDisplay>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isLogin?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  localRedirect?: InputMaybe<PortalDirectionEnum>;
  order?: InputMaybe<Scalars['Float']['input']>;
  prominence?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdatePromotionArtifactDto = {
  _id: Scalars['ID']['input'];
  adsText?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** 2024-06-07T09:00:00.000Z */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  isHot?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rewardedTimeRange?: InputMaybe<Array<TimeRangeDto>>;
  /** 2024-06-07T09:00:00.000Z */
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  strategies: Array<UpdatePromotionStrategyArtifactDto>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePromotionCampaignDto = {
  _id: Scalars['ID']['input'];
  /** 2024-06-07T09:00:00.000Z */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  isHotMobile?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rewardedTimeRange?: InputMaybe<Array<TimeRangeDto>>;
  /** 2024-06-07T09:00:00.000Z */
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  strategies: Array<UpdatePromotionStrategyDto>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePromotionCodeDto = {
  campaignId?: InputMaybe<Scalars['ID']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
  isdn?: InputMaybe<Scalars['String']['input']>;
  packageCode?: InputMaybe<Scalars['String']['input']>;
  rewardId?: InputMaybe<Scalars['ID']['input']>;
  rewardedTimeRange?: InputMaybe<TimeRangeDto>;
  status?: InputMaybe<PromotionCodeStatusEnum>;
  type?: InputMaybe<PromotionCodeTypeEnum>;
};

export type UpdatePromotionConfigDto = {
  _id: Scalars['ID']['input'];
  bonus?: InputMaybe<Scalars['Float']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  packageCode?: InputMaybe<Scalars['String']['input']>;
  promotionId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<PromotionConfigTypeEnum>;
};

export type UpdatePromotionGiftDto = {
  _id: Scalars['ID']['input'];
  avatar?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  promotionId?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdatePromotionIntegrationDto = {
  _id: Scalars['ID']['input'];
  code?: InputMaybe<Scalars['String']['input']>;
  /** 2024-06-07T09:00:00.000Z */
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  isHotMobile?: InputMaybe<Scalars['Boolean']['input']>;
  localRedirect?: InputMaybe<DirectionEnum>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
  /** 2024-06-07T09:00:00.000Z */
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePromotionRewardDto = {
  _id: Scalars['ID']['input'];
  campaignId?: InputMaybe<Scalars['ID']['input']>;
  expiredTime?: InputMaybe<Scalars['DateTime']['input']>;
  mtReward?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rewardNum?: InputMaybe<Scalars['Float']['input']>;
  rewardedTimeRange?: InputMaybe<TimeRangeDto>;
  type?: InputMaybe<PromotionRewardTypeEnum>;
};

export type UpdatePromotionStrategyArtifactDto = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  campaignId?: InputMaybe<Scalars['String']['input']>;
  condition?: InputMaybe<PromotionStrategyConditionDto>;
  messageMT?: InputMaybe<PromotionArtifactStrategyMessageMtDto>;
  packageCode?: InputMaybe<Scalars['String']['input']>;
  reward?: InputMaybe<Array<PromotionStrategyArtifactRewardDto>>;
  specialCode?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePromotionStrategyDto = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  campaignId?: InputMaybe<Scalars['String']['input']>;
  condition?: InputMaybe<PromotionStrategyConditionDto>;
  messageMT?: InputMaybe<PromotionStrategyMessageMtDto>;
  packageCode?: InputMaybe<Scalars['String']['input']>;
  reward?: InputMaybe<PromotionStrategyRewardDto>;
  smsCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRateDto = {
  _id: Scalars['String']['input'];
  createdBy?: InputMaybe<Scalars['Float']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  ratio?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<RateTypeEnum>;
};

export type UpdateRegionCompanyDto = {
  _id: Scalars['ID']['input'];
  benefit?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subCommands?: InputMaybe<Array<UpdateSubCommandDto>>;
  userId?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateSliderDto = {
  _id: Scalars['ID']['input'];
  desc?: InputMaybe<Scalars['String']['input']>;
  isMobileOnly?: InputMaybe<Scalars['Boolean']['input']>;
  localRedirect?: InputMaybe<DirectionEnum>;
  order?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  type: TypeSliderEnum;
  url: Scalars['String']['input'];
  urlThumbnail: Scalars['String']['input'];
};

export type UpdateSubCommandDto = {
  createdBy?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  packageId?: InputMaybe<Scalars['Float']['input']>;
  subCommand?: InputMaybe<Scalars['String']['input']>;
  updatedBy?: InputMaybe<Scalars['Float']['input']>;
  userId?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateTagDto = {
  _id: Scalars['ID']['input'];
  desc?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateTeamDto = {
  _id: Scalars['String']['input'];
  avatar?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  member?: InputMaybe<Array<MemberModelDto>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTeamInTourOfflineDto = {
  _id: Scalars['String']['input'];
  avatar?: InputMaybe<Scalars['String']['input']>;
  members?: InputMaybe<Array<MemberTeamInTournamentOfflineDto>>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TeamInTourStatusEnum>;
  tourOffline?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateTourOfflineDto = {
  _id: Scalars['ID']['input'];
  competitionFormat?: InputMaybe<CompetitionFormatDto>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  game?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFinished?: InputMaybe<Scalars['Boolean']['input']>;
  linkRegisterJoin?: InputMaybe<Scalars['String']['input']>;
  memberInTeam?: InputMaybe<Scalars['Int']['input']>;
  memberInTeams?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numberTeams?: InputMaybe<Scalars['Int']['input']>;
  organizer?: InputMaybe<Scalars['String']['input']>;
  reward?: InputMaybe<Scalars['Float']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<TourOfflineStatusEnum>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  typeTournament?: InputMaybe<TypeTournament>;
};

export type UpdateTournamentOfflineNewsDto = {
  _id: Scalars['String']['input'];
  author?: InputMaybe<AuthorDto>;
  body: Scalars['String']['input'];
  categoryIds: Array<Scalars['String']['input']>;
  coverUrl: Scalars['String']['input'];
  desc: Scalars['String']['input'];
  publishTime: Scalars['DateTime']['input'];
  tagIds: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type UpdateUserTaskDto = {
  completedCount: Scalars['Float']['input'];
  idTask: Scalars['String']['input'];
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateVideoDto = {
  _id: Scalars['ID']['input'];
  author?: InputMaybe<AuthorDto>;
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  desc?: InputMaybe<Scalars['String']['input']>;
  isDislike?: InputMaybe<Scalars['Boolean']['input']>;
  isLike?: InputMaybe<Scalars['Boolean']['input']>;
  isShared?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** 2024-05-24 12:00:00 */
  publishTime?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  url720p?: InputMaybe<Scalars['String']['input']>;
  url1080p?: InputMaybe<Scalars['String']['input']>;
  urlFile?: InputMaybe<Scalars['String']['input']>;
  urlThumbnail?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVideoTournamentOfflineDto = {
  _id: Scalars['String']['input'];
  bracketId?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  tournametId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TypeVideoTournamentOfflineEnum>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type UserGraphQl = {
  __typename?: 'UserGraphQL';
  avatar?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  userId: Scalars['Int']['output'];
};

export type UserTask = {
  __typename?: 'UserTask';
  _id: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Float']['output']>;
  task: Array<Task>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  user: Scalars['Float']['output'];
};

export type UserTaskConnection = {
  __typename?: 'UserTaskConnection';
  items?: Maybe<Array<UserTask>>;
  meta: BasePaginationMeta;
};

export type UserTaskPaginateDto = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type Video = {
  __typename?: 'Video';
  _id: Scalars['String']['output'];
  alias?: Maybe<Scalars['String']['output']>;
  author?: Maybe<User>;
  categories?: Maybe<Array<CategoryGraphQl>>;
  categoryIds: Array<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  desc: Scalars['String']['output'];
  dislike?: Maybe<Scalars['Int']['output']>;
  dislikes?: Maybe<Array<User>>;
  isDeleted: Scalars['Boolean']['output'];
  isPin: Scalars['Boolean']['output'];
  like?: Maybe<Scalars['Int']['output']>;
  likes?: Maybe<Array<User>>;
  name: Scalars['String']['output'];
  publishTime: Scalars['String']['output'];
  shared: Scalars['Int']['output'];
  status: VideoStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  url720p?: Maybe<Scalars['String']['output']>;
  url1080p?: Maybe<Scalars['String']['output']>;
  urlFile?: Maybe<Scalars['String']['output']>;
  urlThumbnail?: Maybe<Scalars['String']['output']>;
  view: Scalars['Int']['output'];
};

export type VideoPaginate = {
  __typename?: 'VideoPaginate';
  items: Array<Video>;
  meta: BasePaginationMeta;
};

export type VideoPaginateDto = {
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  isPin?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
};

export type VideoResultGraphQl = {
  __typename?: 'VideoResultGraphQL';
  items: Array<VideoSearchGraphQl>;
  meta: BasePaginationMeta;
};

export type VideoSearchGraphQl = {
  __typename?: 'VideoSearchGraphQL';
  _id: Scalars['String']['output'];
  alias?: Maybe<Scalars['String']['output']>;
  author?: Maybe<User>;
  categories: Array<CategoryGraphQl>;
  categoryIds: Array<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  desc: Scalars['String']['output'];
  dislike?: Maybe<Scalars['Int']['output']>;
  dislikes?: Maybe<Array<User>>;
  isDeleted: Scalars['Boolean']['output'];
  isPin: Scalars['Boolean']['output'];
  like?: Maybe<Scalars['Int']['output']>;
  likes?: Maybe<Array<User>>;
  name: Scalars['String']['output'];
  publishTime: Scalars['String']['output'];
  shared: Scalars['Int']['output'];
  status: VideoStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  url720p?: Maybe<Scalars['String']['output']>;
  url1080p?: Maybe<Scalars['String']['output']>;
  urlFile?: Maybe<Scalars['String']['output']>;
  urlThumbnail?: Maybe<Scalars['String']['output']>;
  view: Scalars['Int']['output'];
};

export enum VideoStatusEnum {
  PENDING = 'PENDING',
  PUBLISHED = 'PUBLISHED',
  REFUSE = 'REFUSE',
  WAITING = 'WAITING',
}

export type VideoTournamentOffline = {
  __typename?: 'VideoTournamentOffline';
  _id: Scalars['String']['output'];
  bracketId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  link: Scalars['String']['output'];
  tournametId: Scalars['ID']['output'];
  type: TypeVideoTournamentOfflineEnum;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type VideoTournamentOfflineExtend = {
  __typename?: 'VideoTournamentOfflineExtend';
  _id: Scalars['String']['output'];
  bracketId: Scalars['ID']['output'];
  bracketInTour: BracketInTour;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  link: Scalars['String']['output'];
  tournametId: Scalars['ID']['output'];
  type: TypeVideoTournamentOfflineEnum;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type VideoTournamentOfflinePaginateDto = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  tournametId: Scalars['ID']['input'];
  type?: InputMaybe<TypeVideoTournamentOfflineEnum>;
};

export type VideoTournamentOfflinePagination = {
  __typename?: 'VideoTournamentOfflinePagination';
  items: Array<VideoTournamentOfflineExtend>;
  meta: BasePaginationMeta;
};

export type Loyalty = {
  __typename?: 'loyalty';
  _id: Scalars['String']['output'];
  desc: Scalars['String']['output'];
  name: Scalars['String']['output'];
  point: Scalars['Int']['output'];
  userId?: Maybe<Scalars['Int']['output']>;
};
