import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTeamInTourOfflineMutationVariables = Types.Exact<{
  input: Types.UpdateTeamInTourOfflineDto;
}>;

export type UpdateTeamInTourOfflineMutationResponse = { __typename?: 'Mutation' } & {
  updateTeamInTourOffline: { __typename?: 'TeamInTourOffline' } & Pick<
    Types.TeamInTourOffline,
    | '_id'
    | 'avatar'
    | 'createdAt'
    | 'createdBy'
    | 'inMatch'
    | 'isDeleted'
    | 'isFinished'
    | 'name'
    | 'status'
    | 'tourOffline'
    | 'updatedAt'
    | 'updatedBy'
  > & {
      members?: Types.Maybe<
        Array<
          { __typename?: 'MemberTeamInTournamentOffline' } & Pick<Types.MemberTeamInTournamentOffline, 'name' | 'uid'>
        >
      >;
    };
};

export const UpdateTeamInTourOfflineDocument = gql`
  mutation updateTeamInTourOffline($input: UpdateTeamInTourOfflineDto!) {
    updateTeamInTourOffline(input: $input) {
      _id
      avatar
      createdAt
      createdBy
      inMatch
      isDeleted
      isFinished
      members {
        name
        uid
      }
      name
      status
      tourOffline
      updatedAt
      updatedBy
    }
  }
`;
export function useUpdateTeamInTourOfflineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeamInTourOfflineMutationResponse,
    UpdateTeamInTourOfflineMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTeamInTourOfflineMutationResponse, UpdateTeamInTourOfflineMutationVariables>(
    UpdateTeamInTourOfflineDocument,
    options,
  );
}
export type UpdateTeamInTourOfflineMutationHookResult = ReturnType<typeof useUpdateTeamInTourOfflineMutation>;
export type UpdateTeamInTourOfflineMutationResult = Apollo.MutationResult<UpdateTeamInTourOfflineMutationResponse>;
export type UpdateTeamInTourOfflineMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamInTourOfflineMutationResponse,
  UpdateTeamInTourOfflineMutationVariables
>;
