import { callApi } from 'src/apis/connection/callApi';

export type SignInPayload = {
  type: string;
  username: string;
  password: string;
  otpPhone?: string;
};

export type ResetPasswordPayload = {
  confirmPassword: string;
  oldPassword: string;
  newPassword: string;
  username: string;
};

export type ResrtPasswordWithOTP = {
  confirmPassword: string;
  oldPassword: string;
  newPassword: string;
  phone: string;
  otpPhone: string;
};

export default {
  signIn(body: SignInPayload): Promise<any> {
    return callApi({ method: 'post', uri: '/iam/v1/auth/login-admin', body, isCheckToken: false });
  },
  resetPassword(body: ResetPasswordPayload): Promise<any> {
    return callApi({ method: 'put', uri: '/iam/v1/admin/update-password', body });
  },
  getAdministratorById(id: number): Promise<any> {
    return callApi({ method: 'get', uri: `/iam-admin/api/account-cms/find/${id}` });
  },
  getAdminModule(): Promise<any> {
    return callApi({ method: 'get', uri: 'iam/v1/module/menu' });
  },
  getAdminDetail(id: number): Promise<any> {
    return callApi({ method: 'get', uri: `/iam/v1/admin/${id}/detail` });
  },
  getOTP(query: any): Promise<any> {
    return callApi({ method: 'get', uri: '/iam/v1/auth/send-otp-admin', query, isCheckToken: false });
  },
  resetPasswordWithOTP(body: ResrtPasswordWithOTP): Promise<any> {
    return callApi({ method: 'put', uri: '/iam/v1/admin/update-password-with-otp', body, isCheckToken: false });
  },
};
