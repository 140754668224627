import dayjs from 'dayjs';
import jwtDecode, { JwtPayload } from 'jwt-decode';

const TOKEN_KEY = 'accessToken';

const isValidToken = (token: string): boolean => {
  if (token) {
    return (jwtDecode<JwtPayload>(token).exp as number) > dayjs().unix();
  }
  return false;
};

const setSession = (accessToken: string) => {
  if (accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken);
  } else {
    removeToken();
  }
};

const removeToken = (): void => {
  localStorage.removeItem(TOKEN_KEY);
};

const getToken = (): string | null => {
  return localStorage.getItem(TOKEN_KEY);
};

const getUserId = () => {
  const token = localStorage.getItem(TOKEN_KEY) as string;
  const payload = jwtDecode(token) as any;

  return parseInt(payload.id);
};

export { isValidToken, setSession, getUserId, removeToken, getToken };
