import { Breadcrumb } from 'antd';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_DETAIL_ERROR_MESSAGE } from 'src/constants/message';
import TeamInTourForm from 'src/sections/teamInTour/TeamInTourForm';
import Page from '../../../../components/Container';
import { useTeamInTourOfflineQuery } from 'src/graphql/queries/teamInTourOffline.graphql';
import { TeamInTourOffline } from 'src/graphql/type.interface';
import { PATH } from 'src/configs/path';

export default () => {
  const navigate = useNavigate();
  const id = useParams()._id as string;
  const [currentTeamInTour, setCurrentTeamInTour] = useState<TeamInTourOffline>();

  useTeamInTourOfflineQuery({
    variables: {
      id,
    },
    onCompleted: (data) => {
      setCurrentTeamInTour(data.teamInTourOffline);
    },
    onError() {
      enqueueSnackbar(GET_DETAIL_ERROR_MESSAGE, { variant: 'error' });
    },
  });

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'pointer' }} onClick={() => navigate(PATH.tournamentOffline.detail(id))}>
                Đội trong giải đấu
              </p>
            ),
          },
          {
            title: `Chỉnh sửa`,
          },
        ]}
      />
      <Page title="Sửa đội trong giải đấu">
        <TeamInTourForm currentTeamInTour={currentTeamInTour} />
      </Page>
    </>
  );
};
