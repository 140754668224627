import { Breadcrumb, Button, Card, Col, Form, Input, Row } from 'antd';
import { FIELD_REQUIRED, REALNAME_FORM_MESSAGE, UPDATE_SUCCESS_MESSAGE } from '../../constants/message';

import AuthRepositories from 'src/apis/service/administrator';
import Container from 'src/components/Container';
import PermissionRepository from 'src/apis/service/permission';
import { enqueueSnackbar } from 'notistack';
import { getUserId } from 'src/utils/jwt';
import { useDetailStore } from '../../stores/index';
import { ChangeEvent, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

type TypeForm = { username: string; fullName: string; email?: string; phone?: string; is2faPhone: boolean };

const Profile = () => {
  const { userDetail, setUserDetail } = useDetailStore();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isAuthenByPhone, setIsAuthenByPhone] = useState<boolean>(false);
  const [detail, setDetail] = useState<any>();
  const userId = getUserId();

  const getUserDetail = () => {
    AuthRepositories.getAdminDetail(userId)
      .then(({ data }) => {
        setUserDetail(data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    (async () => {
      const { success, data } = await AuthRepositories.getAdminDetail(userId);

      if (success) {
        setDetail(data);
      }
    })();
  }, [userId]);

  useEffect(() => {
    if (detail) {
      const { username, fullName, email, phone, is2faPhone } = detail;
      setIsAuthenByPhone(is2faPhone);

      form.setFieldsValue({ username, fullName, email, phone, is2faPhone });
    }
  }, [detail]);

  const { mutateAsync: mutateAsyncUpdateUser } = useMutation(
    (payload: any) => PermissionRepository.updateUser(payload),
    {
      onSuccess(data) {
        if (data.code === 200) {
          enqueueSnackbar(UPDATE_SUCCESS_MESSAGE, {
            variant: 'success',
          });
          getUserDetail();
          navigate('/');
        } else {
          enqueueSnackbar(data.message, {
            variant: 'error',
          });
        }
      },
    },
  );

  const onFinish = async (form: TypeForm) => {
    const { username, fullName, email, phone, is2faPhone } = form;
    const payload = {
      id: userDetail?.id,
      username,
      fullName,
      email,
      phone,
      is2faPhone,
    };
    mutateAsyncUpdateUser(payload);
  };

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: <p> Thông tin cá nhân </p>,
          },
        ]}
      />
      <Container title="Sửa tài khoản">
        <Card>
          <Form form={form} onFinish={onFinish}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Tên đăng nhập <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  required={false}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name="username"
                >
                  <Input disabled />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Họ và tên <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  required={false}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name="fullName"
                  rules={[
                    { required: true, message: FIELD_REQUIRED },
                    {
                      pattern:
                        /^(?!.* {2,})[a-zA-ZđĐàÀáÁảẢãÃạẠăĂằẰắẮẳẲẵẴặẶâÂầẦấẤẩẨẫẪậẬêÊềỀếẾểỂễỄệỆôÔồỒốỐổỔỗỖộỘơƠờỜớỚởỞỡỠợỢưƯừỪứỨửỬữỮựỰỳỲýÝỷỶỹỸỵỴ\s]{6,50}$/,
                      message: REALNAME_FORM_MESSAGE,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label={<span>Email</span>}
                  required={false}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name="email"
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Số điện thoại <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  required={false}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name="phone"
                  rules={[
                    { required: true, message: FIELD_REQUIRED },
                    {
                      pattern: /^84\d{9}$/,
                      message: 'Số điện thoại phải dạng số và bắt đầu bằng 84',
                    },
                  ]}
                >
                  <Input
                    onInput={(e: ChangeEvent<HTMLInputElement>) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
              <Button type="primary" htmlType="submit">
                Lưu
              </Button>
            </div>
          </Form>
        </Card>
      </Container>
    </>
  );
};

export default Profile;
